<template>
  <div class="mx-auto max-w-5xl">
    <div class="mx-auto mt-32 max-w-2xl sm:text-center">
      <h1 class="text-3xl font-bold tracking-tight text-gray-800 sm:text-4xl">{{ $t('shipping_countries.title') }}</h1>
      <p class="mt-6 text-lg leading-8 text-gray-500">
        {{ $t('shipping_countries.subtitle') }}
      </p>
    </div>

    <div v-if="configuration.shippingCountries" class="relative z-30 grid grid-cols-2 gap-8 py-16 pr-10 sm:grid-cols-3">
      <div
        v-for="country in configuration.shippingCountries"
        :key="country.code"
        class="ml-8 flex items-center gap-4 text-sm sm:ml-20"
      >
        <img
          class="h-7 w-10 rounded object-cover ring-1 ring-gray-100"
          :src="`https://flagcdn.com/h40/${country.code.toLowerCase()}.png`"
        />
        {{ $t(`countries.${country.code}`) }}
      </div>
    </div>

    <div v-else class="flex h-full w-full items-center justify-center">
      <sl-spinner style="font-size: 5rem; --indicator-color: #4f46e5; --track-color: #eee"></sl-spinner>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useSeoMeta } from '@unhead/vue'
import Axios from 'axios'
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import { Configuration } from '@/stores/admin/configuration'

const configuration = Configuration()
const i18n = useI18n()

useSeoMeta({
  title: i18n.t('shipping_countries.seo.title'),
  description: i18n.t('shipping_countries.seo.description'),
  ogDescription: i18n.t('shipping_countries.seo.description'),
  ogTitle: i18n.t('shipping_countries.seo.title'),
  ogImage: 'https://img.printplus.app/misc/og_image.jpg',
  ogUrl: 'https://printplus.app/shipping-countries',
  ogSiteName: 'PrintPlus',
  ogType: 'website',
  ogLocale: i18n.locale
})

onMounted(async () => {
  const response = await Axios.create({
    baseURL: 'https://api.printplus.app',
    headers: {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }
  }).get(`/configuration`)
  console.log(response)

  const quoteResponse = await Axios.create({
    baseURL: 'https://api.printplus.app',
    headers: {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }
  }).post(
    `/cart/quote`,
    {
      line_items: [],
      currency: 'czk'
    },
    { withCredentials: true }
  )
  console.log(quoteResponse)
})
</script>
