<template>
  <div class="mx-auto max-w-3xl bg-white py-32 text-base leading-7 text-gray-700 lg:px-8">
    <figure>
      <video
        class="aspect-video rounded-xl"
        controls
        controlsList="nodownload"
        poster="https://img.printplus.app/videos/PrintPlus – Product Creation.jpg"
      >
        <source src="https://img.printplus.app/videos/PrintPlus – Product Creation.mp4" type="video/mp4" />
        <source src="https://img.printplus.app/videos/PrintPlus – Product Creation.webm" type="video/webm" />
      </video>
    </figure>

    <p class="mt-10 text-base font-semibold leading-7 text-indigo-600">{{ $t('how_it_works.caption') }}</p>
    <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
      {{ $t('how_it_works.title') }}
    </h1>
    <p class="mt-6 text-xl leading-8">
      {{ $t('how_it_works.description') }}
    </p>

    <div class="mt-10 max-w-2xl">
      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">{{ $t('how_it_works.step_1.title') }}</h2>
      <p class="mt-6">
        {{ $t('how_it_works.step_1.text_1') }}
      </p>
    </div>

    <div class="mt-10 max-w-2xl">
      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">{{ $t('how_it_works.step_2.title') }}</h2>
      <p class="mt-6">
        {{ $t('how_it_works.step_2.text_1') }}
      </p>

      <figure class="mt-10">
        <img
          alt=""
          class="aspect-video rounded-xl bg-gray-50 object-cover"
          src="@/assets/images/tutorial/tutorial1.jpg"
        />
        <figcaption class="mt-4 flex gap-x-2 text-sm leading-6 text-gray-500">
          <svg
            aria-hidden="true"
            class="mt-0.5 h-5 w-5 flex-none text-gray-300"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              clip-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
              fill-rule="evenodd"
            />
          </svg>
          {{ $t('how_it_works.step_2.text_2') }}
        </figcaption>
      </figure>
    </div>

    <div class="mt-10 max-w-2xl">
      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">{{ $t('how_it_works.step_3.title') }}</h2>
      <p class="mt-6">
        {{ $t('how_it_works.step_3.text_1') }}
      </p>

      <figure class="mt-10">
        <img
          alt=""
          class="aspect-video rounded-xl bg-gray-50 object-cover"
          src="@/assets/images/tutorial/tutorial2.jpg"
        />
        <figcaption class="mt-4 flex gap-x-2 text-sm leading-6 text-gray-500">
          <svg
            aria-hidden="true"
            class="mt-0.5 h-5 w-5 flex-none text-gray-300"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              clip-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
              fill-rule="evenodd"
            />
          </svg>
          {{ $t('how_it_works.step_3.text_2') }}
        </figcaption>
      </figure>
    </div>

    <div class="mt-10 max-w-2xl">
      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">{{ $t('how_it_works.step_4.title') }}</h2>
      <p class="mt-6">
        {{ $t('how_it_works.step_4.text_1') }}
      </p>
      <p class="mt-8">
        {{ $t('how_it_works.step_4.text_2') }}
      </p>

      <figure class="mt-10">
        <img
          alt=""
          class="aspect-video rounded-xl bg-gray-50 object-cover"
          src="@/assets/images/tutorial/tutorial3.jpg"
        />
        <figcaption class="mt-4 flex gap-x-2 text-sm leading-6 text-gray-500">
          <svg
            aria-hidden="true"
            class="mt-0.5 h-5 w-5 flex-none text-gray-300"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              clip-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
              fill-rule="evenodd"
            />
          </svg>
          {{ $t('how_it_works.step_4.text_3') }}
        </figcaption>
      </figure>
    </div>

    <div class="mt-10 max-w-2xl">
      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">{{ $t('how_it_works.step_5.title') }}</h2>
      <p class="mt-6">
        {{ $t('how_it_works.step_5.text_1') }}
      </p>
      <p class="mt-8">
        {{ $t('how_it_works.step_5.text_2') }}
      </p>

      <figure class="mt-10">
        <img
          alt=""
          class="aspect-video rounded-xl bg-gray-50 object-cover"
          src="@/assets/images/tutorial/tutorial4.jpg"
        />
        <figcaption class="mt-4 flex gap-x-2 text-sm leading-6 text-gray-500">
          <svg
            aria-hidden="true"
            class="mt-0.5 h-5 w-5 flex-none text-gray-300"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              clip-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
              fill-rule="evenodd"
            />
          </svg>
          {{ $t('how_it_works.step_5.text_3') }}
        </figcaption>
      </figure>
    </div>

    <div class="mt-10 max-w-2xl">
      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">{{ $t('how_it_works.step_6.title') }}</h2>
      <p class="mt-6">
        {{ $t('how_it_works.step_6.text_1') }}
      </p>
    </div>

    <div class="mt-10 max-w-2xl">
      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">
        {{ $t('how_it_works.step_7.title') }}
      </h2>
      <p class="mt-6">
        {{ $t('how_it_works.step_7.text_1') }}
      </p>

      <figure class="mt-10">
        <img
          alt=""
          class="aspect-video rounded-xl bg-gray-50 object-cover"
          src="@/assets/images/tutorial/tutorial5.jpg"
        />
        <figcaption class="mt-4 flex gap-x-2 text-sm leading-6 text-gray-500">
          <svg
            aria-hidden="true"
            class="mt-0.5 h-5 w-5 flex-none text-gray-300"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              clip-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
              fill-rule="evenodd"
            />
          </svg>
          {{ $t('how_it_works.step_7.text_2') }}
        </figcaption>
      </figure>
    </div>

    <div class="mt-10 max-w-2xl">
      <p class="mt-6">
        {{ $t('how_it_works.step_8.text_1') }}
      </p>
      <p class="mt-8">
        {{ $t('how_it_works.step_8.text_2') }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useSeoMeta } from '@unhead/vue'
import { useI18n } from 'vue-i18n'

const i18n = useI18n()

useSeoMeta({
  title: i18n.t('how_it_works.seo.title'),
  description: i18n.t('how_it_works.seo.description'),
  ogDescription: i18n.t('how_it_works.seo.description'),
  ogTitle: i18n.t('how_it_works.seo.title'),
  ogImage: 'https://img.printplus.app/misc/og_image.jpg',
  ogUrl: 'https://printplus.app/tutorial',
  ogSiteName: 'PrintPlus',
  ogType: 'website',
  ogLocale: i18n.locale
})
</script>
