<template>
  <div class="overflow-hidden bg-white font-sans antialiased">
    <div class="relative flex flex-col justify-between overflow-hidden">
      <Header v-if="!$route.meta.hideHeader" :printer="printer" />
      <main
        :class="$route.meta.fullWidth ? 'flex w-full min-h-screen' : 'mx-auto flex w-full min-h-screen max-w-7xl px-6'"
      >
        <router-view />
      </main>
      <Footer />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Footer from '@/views/application/footer.vue'
import Header from '@/views/application/header.vue'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const printer = computed(() => {
  return (route.params.printer as string) || 'all'
})
</script>
