<template>
  <footer aria-labelledby="footer-heading" class="bg-gray-900">
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div class="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
      <div class="xl:grid xl:grid-cols-3 xl:gap-8">
        <div class="space-y-8">
          <img alt="PrintPlus" class="h-7" src="@/assets/images/logo_wide_white.png" />
          <p class="text-sm leading-6 text-gray-300">
            {{ $t('footer.heading') }}
          </p>
          <div>
            <a
              class="cursor-pointer rounded border border-gray-500 px-6 py-2 text-center text-sm text-white hover:bg-gray-700"
              href="https://apps.shopify.com/printplus"
              target="_blank"
            >
              {{ $t('footer.install_shopify_app') }}
            </a>
          </div>
        </div>
        <div class="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div></div>
            <div class="mt-0">
              <h3 class="text-sm font-semibold leading-6 text-white">{{ $t('footer.product') }}</h3>
              <ul class="mt-6 space-y-4" role="list">
                <li>
                  <router-link
                    class="text-sm leading-6 text-gray-300 hover:text-white"
                    :to="$localizedPath({ name: 'tutorial_path' })"
                  >
                    {{ $t('footer.how_it_works') }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    class="text-sm leading-6 text-gray-300 hover:text-white"
                    :to="$localizedPath({ name: 'features_path' })"
                  >
                    {{ $t('footer.features') }}
                  </router-link>
                </li>
                <li>
                  <a
                    class="flex items-center gap-1 text-sm leading-6 text-gray-300 hover:text-white"
                    href="https://apps.shopify.com/printplus"
                    target="_blank"
                  >
                    {{ $t('footer.shopify_app') }}
                    <svg
                      class="h-3 w-3"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3 class="text-sm font-semibold leading-6 text-white">{{ $t('footer.support') }}</h3>
              <ul class="mt-6 space-y-4" role="list">
                <li>
                  <router-link
                    class="text-sm leading-6 text-gray-300 hover:text-white"
                    :to="$localizedPath({ name: 'pricing_path' })"
                  >
                    {{ $t('footer.pricing') }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    class="text-sm leading-6 text-gray-300 hover:text-white"
                    :to="$localizedPath({ name: 'shipping_countries_path' })"
                  >
                    {{ $t('footer.shipping_countries') }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    class="text-sm leading-6 text-gray-300 hover:text-white"
                    :to="$localizedPath({ name: 'faq_path' })"
                  >
                    {{ $t('footer.faq') }}
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="mt-10 md:mt-0">
              <h3 class="text-sm font-semibold leading-6 text-white">{{ $t('footer.legal') }}</h3>
              <ul class="mt-6 space-y-4" role="list">
                <li>
                  <router-link
                    class="text-sm leading-6 text-gray-300 hover:text-white"
                    :to="$localizedPath({ name: 'privacy_path' })"
                  >
                    {{ $t('footer.privacy') }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    class="text-sm leading-6 text-gray-300 hover:text-white"
                    :to="$localizedPath({ name: 'terms_path' })"
                  >
                    {{ $t('footer.terms') }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        class="sl-theme-dark mt-16 flex flex-col-reverse items-center justify-between gap-4 border-t border-white/10 pt-8 sm:mt-20 sm:flex-row lg:mt-24"
      >
        <p class="text-xs leading-5 text-gray-400">
          &copy; {{ new Date().getFullYear() }} {{ $t('footer.copyright') }}
        </p>

        <div class="flex items-center gap-4">
          <sl-select
            class="sl-theme-dark w-40"
            placeholder="Language"
            size="small"
            :value="$i18n.locale"
            @sl-change="onLocaleChange($event.target.value)"
          >
            <sl-option
              v-for="(language, index) in SUPPORTED_LANGUAGES"
              :key="index"
              size="small"
              :value="language.locale"
            >
              <div class="flex items-center gap-2">
                {{ language.flag }}
                {{ language.name }}
              </div>
            </sl-option>
          </sl-select>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router'

import { SUPPORTED_LANGUAGES } from '@/i18n'
import { UserSession } from '@/stores/user_session'

const userSession = UserSession()
const router = useRouter()

function onLocaleChange(locale: string) {
  userSession.setCurrentLocale(locale)
  if (locale === 'en') {
    locale = ''
  }
  router.push({ params: { locale: locale } })
}
</script>
