<template>
  <div>
    <FeaturesHeader />
    <Shipping />
    <Production />
    <ShopifyApp />
    <ShopifyAppCTA />
    <FAQ />
  </div>
</template>

<script lang="ts" setup>
import { useSeoMeta } from '@unhead/vue'
import { useI18n } from 'vue-i18n'

import FeaturesHeader from '@/views/application/features/features_header.vue'
import Production from '@/views/application/features/production.vue'
import Shipping from '@/views/application/features/shipping.vue'
import ShopifyApp from '@/views/application/features/shopify_app.vue'
import ShopifyAppCTA from '@/views/application/features/shopify_app_cta.vue'
import FAQ from '@/views/application/index/faq.vue'

const i18n = useI18n()

useSeoMeta({
  title: i18n.t('features.seo.title'),
  description: i18n.t('features.seo.description'),
  ogDescription: i18n.t('features.seo.description'),
  ogTitle: i18n.t('features.seo.title'),
  ogImage: 'https://img.printplus.app/misc/og_image.jpg',
  ogUrl: 'https://printplus.app/features',
  ogSiteName: 'PrintPlus',
  ogType: 'website',
  ogLocale: i18n.locale
})
</script>
