<template>
  <div aria-hidden="true" class="absolute inset-x-0 top-40 z-20 transform-gpu overflow-hidden blur-3xl sm:-top-40">
    <div
      class="relative left-[calc(50%-11rem)] z-20 aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#40B5F2] to-[#FF829F] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[70.1875rem]"
      style="
        clip-path: polygon(
          74.1% 44.1%,
          100% 61.6%,
          97.5% 26.9%,
          85.5% 0.1%,
          80.7% 2%,
          72.5% 32.5%,
          60.2% 62.4%,
          52.4% 68.1%,
          47.5% 58.3%,
          45.2% 34.5%,
          27.5% 76.7%,
          0.1% 64.9%,
          17.9% 100%,
          27.6% 76.8%,
          76.1% 97.7%,
          74.1% 44.1%
        );
      "
    ></div>
  </div>
</template>
