<template>
  <g class="cursor-pointer opacity-0 group-hover:opacity-100">
    <g fill="none" fill-rule="evenodd">
      <circle cx="16" cy="16" fill="#cc1919" r="16" />
      <g
        fill="none"
        stroke="#fff"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        transform="matrix(-1 0 0 1 28 4)"
      >
        <line x1="18" x2="6" y1="6" y2="18"></line>
        <line x1="6" x2="18" y1="6" y2="18"></line>
      </g>
    </g>
  </g>
</template>
