<template>
  <div class="flex min-h-full items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
    <div class="w-full max-w-sm space-y-8">
      <div>
        <img alt="PrintPlus" class="mx-auto h-12 w-auto" src="@/assets/images/logo.png" />
        <h2 class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Create your PrintPlus account</h2>
        <p class="mt-2 text-center text-sm text-gray-600">
          Already have an account?
          <router-link class="font-medium text-indigo-600 hover:text-indigo-500" :to="{ name: 'sign_in_path' }">
            Sign in
          </router-link>
        </p>
      </div>
      <form class="mt-8 space-y-6" @submit.prevent="signUp()">
        <input name="remember" type="hidden" value="true" />
        <div class="-space-y-px rounded-md">
          <sl-input
            v-model="email"
            :help-text="errors.email ? errors.email[0] : ''"
            label="Email address"
            placeholder="Your email address"
            type="email"
          ></sl-input>

          <div>
            <sl-input
              v-model="password"
              :data-invalid="errors.password"
              label="Password"
              password-toggle
              placeholder="Your password"
              type="password"
            ></sl-input>
            <div v-if="errors.password" class="px-2 py-1 text-sm text-red-600">
              {{ errors.password[0] }}
            </div>
          </div>
        </div>

        <div>
          <div
            class="group relative flex w-full cursor-pointer justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            @click="signUp()"
          >
            <span class="absolute inset-y-0 left-0 flex items-center pl-3">
              <sl-icon class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" name="key" />
            </span>
            Sign up
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import UserServices from '@/services/user_service'
import { UserSession } from '@/stores/user_session'
import { alert } from '@/utils/alert'

const userSession = UserSession()
const email = ref('')
const password = ref('')
const errors = ref({} as any)

async function signUp() {
  try {
    const response = await UserServices.signUp({
      email: email.value,
      password: password.value
    })

    userSession.setCurrentUser(response)

    window.location.href = '/shopify-app'
  } catch (error) {
    errors.value = error.response.data.errors
    alert(error.response.data.message, 'danger')
  }
}
</script>
