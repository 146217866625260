<template>
  <div>
    <Header :hide-cta="true" />
    <Hero />
    <Steps />
    <Pricing />
    <Features />
    <CTA />
    <FAQ />
  </div>
</template>

<script lang="ts" setup>
import { useSeoMeta } from '@unhead/vue'
import { useI18n } from 'vue-i18n'

import Header from '@/views/application/header.vue'
import CTA from '@/views/application/index/cta.vue'
import FAQ from '@/views/application/index/faq.vue'
import Features from '@/views/application/index/features.vue'
import Hero from '@/views/application/index/hero.vue'
import Pricing from '@/views/application/index/pricing.vue'
import Steps from '@/views/application/index/steps.vue'

const i18n = useI18n()

useSeoMeta({
  title: i18n.t('homepage.seo.title'),
  description: i18n.t('homepage.seo.description'),
  ogDescription: i18n.t('homepage.seo.description'),
  ogTitle: i18n.t('homepage.seo.title'),
  ogImage: 'https://img.printplus.app/misc/og_image.jpg',
  ogUrl: 'https://printplus.app',
  ogSiteName: 'PrintPlus',
  ogType: 'website',
  ogLocale: i18n.locale
})
</script>
