<template>
  <div>
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue'

import UserService from '@/services/user_service'
import { Configuration } from '@/stores/admin/configuration'
import { UserSession } from '@/stores/user_session'

const configuration = Configuration()
const userSession = UserSession()

onMounted(async () => {
  const response = await UserService.getCurrentSession()
  userSession.setCurrentSession(response)
  await configuration.initialize()
})
</script>
