<template>
  <div class="w-full py-8 sm:py-16 bg-gray-100">
    <div class="mx-auto mb-20 max-w-7xl p-6">
      <Breadcrumbs :breadcrumbs="breadcrumbs.concat({ name: $t(`style_categories.${category}.plural`) })" />

      <div v-if="loading" class="my-32 flex w-full items-center justify-center">
        <sl-spinner style="font-size: 5rem; --indicator-color: #4f46e5; --track-color: #eee"></sl-spinner>
      </div>

      <div v-else class="mt-6 flex gap-10">
        <div class="w-1/2">
          <img class="w-full rounded-lg" :src="categoryImagePath(category)" />
        </div>
        <div class="w-1/2">
          <div class="mb-3">
            <h1 class="text-3xl font-semibold">{{ $t(`product_category.title.${category}`) }}</h1>
          </div>

          <div class="mb-6 text-gray-600">
            {{ $t(`product_category.description.${category}`) }}
          </div>

          <div
            v-for="(styles, qualityLevel) in stylesPerQualityLevel"
            :key="qualityLevel.toString()"
            class="mt-3 flex justify-between rounded-lg bg-white shadow-lg p-4"
          >
            <div class="mr-10">
              <div class="text-lg font-medium">{{ $t(`product_category.quality.title.${qualityLevel}`) }}</div>
              <div class="mt-3 text-sm text-gray-600">
                <div class="min-h-[50px]">
                  <div class="flex w-full flex-wrap gap-1">
                    <sl-tooltip
                      v-for="variant in variantsFromStyles(styles)"
                      :key="variant.id"
                      :content="variant.name"
                      @mouseover="setHoveredVariant(variant, qualityLevel as string)"
                    >
                      <svg
                        class="h-4 w-4 cursor-pointer rounded border border-gray-200 hover:border-gray-400 hover:shadow"
                      >
                        <rect :fill="variant.hex_color" height="100%" width="100%" x="0" y="0" />
                        <image
                          v-if="variant.dark_heather"
                          height="100%"
                          href="https://img.printplus.app/images/heather_dark_500.png"
                          width="100%"
                          x="0"
                          y="0"
                        />
                        <image
                          v-if="variant.light_heather"
                          height="100%"
                          href="https://img.printplus.app/images/heather_light_500.png"
                          width="100%"
                          x="0"
                          y="0"
                        />
                      </svg>
                    </sl-tooltip>
                  </div>
                  <div class="mt-4 flex items-center gap-4">
                    <div>
                      {{ styles.flatMap((style: Style) => style.sizing?.[0]?.name)?.[0] }} -
                      {{ styles.flatMap((style: Style) => style.sizing?.[style.sizing.length - 1]?.name)?.[0] }}
                    </div>

                    <div class="text-gray-300">|</div>

                    <div>
                      {{ $t('product_category.quality.price_from') }}
                      <Price
                        :amount="_.max(
                          styles.flatMap((style: Style) =>
                            style.pricing?.breakpoints.map((bp) => bp[2][userSession.currentCurrency.code])
                          )
                        )"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col items-center justify-center">
              <router-link
                :to="
                  $localizedPath({
                    name: 'product_design_path',
                    params: {
                      category: category,
                      quality: qualityLevel
                    }
                  })
                "
              >
                <sl-button variant="primary">
                  {{ $t('product_category.quality.cta_design') }}
                </sl-button>
              </router-link>
              <router-link
                class="mt-5 text-sm text-indigo-600 hover:underline"
                :to="
                  $localizedPath({
                    name: 'product_category_quality_path',
                    params: { category: category, quality: qualityLevel }
                  })
                "
              >
                {{ $t('product_category.quality.learn_more') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import Breadcrumbs from '@/components/shared/Breadcrumbs.vue'
import Price from '@/components/shared/Price.vue'
import StyleService from '@/services/style_service'
import { UserSession } from '@/stores/user_session'
import { Breadcrumb, Style, StyleVariant } from '@/types'
import { variantColorSort } from '@/utils/color'

const userSession = UserSession()
const allStyles = ref([] as Style[])
const variants = ref([] as StyleVariant[])
const stylesPerQualityLevel = ref({} as { [key: string]: Style[] })
const loading = ref(true)
const hoveredVariant = ref(null as StyleVariant | null)
const hoveredQualityLevel = ref(null as string | null)
const route = useRoute()
const i18n = useI18n()
const breadcrumbs = [{ name: i18n.t('breadcrumbs.all_products'), link: 'products_path' }] as Breadcrumb[]

const category = computed(() => {
  return route.params.category as string
})

const qualitySort = {
  'standard': 0,
  'premium': 1,
  'organic': 2
}

onMounted(async () => {
  const response = await StyleService.publicCatalogData(route.params.category as string)
  allStyles.value = response.styles
  variants.value = response.variants
  stylesPerQualityLevel.value = _.groupBy(
    response.styles.sort((a, b) => qualitySort[a.quality_level] - qualitySort[b.quality_level]),
    'quality_level'
  )

  loading.value = false
})

function variantsFromStyles(styles: Style[]): StyleVariant[] {
  let filteredVariants = variants.value.filter((variant) => {
    return styles.map((s) => s.id.toString()).includes(variant.style_id.toString())
  })

  return _.uniqBy(filteredVariants, 'name').sort(variantColorSort)
}

function setHoveredVariant(variant: StyleVariant, qualityLevel: string) {
  hoveredVariant.value = variant
  hoveredQualityLevel.value = qualityLevel
}

function categoryImagePath(category: string) {
  switch (category) {
    case 'thsirts':
      return 'https://img.printplus.app/categories/tshirts.png'
    case 'women_thsirts':
      return 'https://img.printplus.app/categories/tshirts.png'
    case 'hoodies':
      return 'https://img.printplus.app/categories/hoodies.png'
    case 'sweatshirts':
      return 'https://img.printplus.app/categories/sweatshirts.png'
    case 'polos':
      return 'https://img.printplus.app/categories/polos.png'
    case 'women_tank_tops':
      return 'https://img.printplus.app/categories/tank_tops.png'
    case 'tank_tops':
      return 'https://img.printplus.app/categories/tank_tops.png'
    case 'tote_bags':
      return 'https://img.printplus.app/categories/totes.png'
    default:
      return 'https://img.printplus.app/categories/tshirts.png'
  }
}
</script>
