<template>
  <div class="mt-20 w-full">
    <div v-if="loading" class="my-32 flex w-full items-center justify-center">
      <sl-spinner style="font-size: 5rem; --indicator-color: #4f46e5; --track-color: #eee"></sl-spinner>
    </div>
    <div v-else>
      <div v-if="order.payment_status === 'paid'" class="mx-auto mb-6 flex max-w-3xl items-center justify-center">
        <div class="mx-auto max-w-2xl px-4 sm:px-6 sm:py-24 lg:grid lg:gap-x-8 lg:px-8 xl:gap-x-24">
          <div>
            <h1 class="text-sm font-medium text-indigo-600">{{ $t('order_page.payment_successful') }}</h1>
            <p class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">{{ $t('order_page.title') }}</p>
            <p class="mt-2 text-base text-gray-500">
              {{ $t('order_page.subtitle') }}
            </p>

            <div
              v-for="(shipment, index) in order.shipments"
              :key="shipment.id"
              class="flex gap-8 items-center bg-indigo-50 py-4 px-6 border border-indigo-100 mt-8 rounded-md"
            >
              <div class="text-indigo-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-16 w-16"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m21 7.5-2.25-1.313M21 7.5v2.25m0-2.25-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3 2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75 2.25-1.313M12 21.75V19.5m0 2.25-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25"
                  />
                </svg>
              </div>
              <dl class="text-sm font-medium">
                <dt class="text-gray-900">Tracking shipment {{ order.shipments.length > 1 ? `#${index + 1}` : '' }}</dt>
                <dd class="mt-2">
                  <span class="mr-2">
                    {{ shipment.carrier }}
                  </span>
                  <a class="text-indigo-600" :href="shipment.tracking_url" target="_blank">
                    {{ shipment.tracking_code }}
                  </a>
                </dd>
              </dl>
            </div>

            <ul
              class="mt-6 divide-y divide-gray-200 border-t border-gray-200 text-sm font-medium text-gray-500"
              role="list"
            >
              <li v-for="orderItem in order.order_items" :key="orderItem.id" class="flex space-x-6 py-6">
                <img
                  class="h-24 w-24 flex-none rounded-md bg-gray-100 object-cover object-center"
                  :src="orderItem.previews[0].image"
                />
                <div class="flex-auto space-y-1">
                  <h3 class="text-gray-900">
                    <a href="#">{{ orderItem.product_title }}</a>
                  </h3>
                  <div class="flex items-center">
                    <div
                      class="mr-1 h-3 w-3 rounded-full"
                      :style="`background-color: ${orderItem.product_variant.variant.hex_color}`"
                    ></div>
                    <div>{{ orderItem.product_variant.variant.name }}</div>
                  </div>
                  <p>{{ $t('shopping_cart.order_item.size') }}: {{ orderItem.product_variant.variant.size }}</p>
                </div>
                <p class="flex-none font-medium text-gray-900">
                  {{ orderItem.quantity }} &cross;
                  <Price :amount="orderItem.fulfilment_price / orderItem.quantity" />
                </p>
              </li>
            </ul>

            <dl class="space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-500">
              <div class="flex justify-between">
                <dt>{{ $t('shopping_cart.summary.subtotal') }}</dt>
                <dd class="text-gray-900"><Price :amount="order.fulfilment_price" /></dd>
              </div>

              <div class="flex justify-between">
                <dt>{{ $t('shopping_cart.summary.shipping') }}</dt>
                <dd class="text-gray-900"><Price :amount="order.shipping_price" /></dd>
              </div>

              <div class="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
                <dt class="text-base">{{ $t('shopping_cart.summary.total') }}</dt>
                <dd class="text-base">
                  <Price :amount="(Number(order.shipping_price) + Number(order.fulfilment_price)).toFixed(2)" />
                </dd>
              </div>
            </dl>

            <dl class="mt-16 grid grid-cols-2 gap-x-4 text-sm text-gray-600">
              <div>
                <dt class="font-medium text-gray-900">{{ $t('order_page.shipping_address_title') }}</dt>
                <dd class="mt-2">
                  <address class="not-italic">
                    <span class="block">{{ order.address.first_name }} {{ order.address.last_name }}</span>
                    <span class="block">{{ order.address.shipping1 }} {{ order.address.shipping2 }}</span>
                    <span class="block">
                      {{ order.address.city }}, {{ order.address.zip }}, {{ order.address.country }}
                    </span>
                  </address>
                </dd>
              </div>
              <!-- <div>
                <dt class="font-medium text-gray-900">Payment Information</dt>
                <dd class="mt-2 space-y-2 sm:flex sm:space-x-4 sm:space-y-0">
                  <div class="flex-none">
                    <svg aria-hidden="true" class="h-6 w-auto" height="24" viewBox="0 0 36 24" width="36">
                      <rect fill="#224DBA" height="24" rx="4" width="36" />
                      <path
                        d="M10.925 15.673H8.874l-1.538-6c-.073-.276-.228-.52-.456-.635A6.575 6.575 0 005 8.403v-.231h3.304c.456 0 .798.347.855.75l.798 4.328 2.05-5.078h1.994l-3.076 7.5zm4.216 0h-1.937L14.8 8.172h1.937l-1.595 7.5zm4.101-5.422c.057-.404.399-.635.798-.635a3.54 3.54 0 011.88.346l.342-1.615A4.808 4.808 0 0020.496 8c-1.88 0-3.248 1.039-3.248 2.481 0 1.097.969 1.673 1.653 2.02.74.346 1.025.577.968.923 0 .519-.57.75-1.139.75a4.795 4.795 0 01-1.994-.462l-.342 1.616a5.48 5.48 0 002.108.404c2.108.057 3.418-.981 3.418-2.539 0-1.962-2.678-2.077-2.678-2.942zm9.457 5.422L27.16 8.172h-1.652a.858.858 0 00-.798.577l-2.848 6.924h1.994l.398-1.096h2.45l.228 1.096h1.766zm-2.905-5.482l.57 2.827h-1.596l1.026-2.827z"
                        fill="#fff"
                      />
                    </svg>
                    <p class="sr-only">Visa</p>
                  </div>
                  <div class="flex-auto">
                    <p class="text-gray-900">Ending with 4242</p>
                    <p>Expires 12 / 21</p>
                  </div>
                </dd>
              </div> -->
            </dl>

            <div class="mt-16 border-t border-gray-200 py-6 text-right">
              <router-link
                class="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                :to="$localizedPath({ name: 'products_path' })"
              >
                {{ $t('order_page.continue_shopping') }}
                <span aria-hidden="true">&rarr;</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import Price from '@/components/shared/Price.vue'
import OrderService from '@/services/order_service'
import { useShoppingCart } from '@/stores/shopping_cart'
import { Order } from '@/types'

const i18n = useI18n()
const order = ref(null as Order)
const loading = ref(true)
const route = useRoute()
const router = useRouter()
const shoppingCart = useShoppingCart()

onMounted(async () => {
  order.value = await OrderService.completeCheckout(route.params.orderId as string, route.params.orderKey as string)

  if (order.value.locale != i18n.locale.value) {
    i18n.locale.value = order.value.locale
    router.push({
      name: 'order_path',
      params: { orderId: order.value.id, orderKey: order.value.key, locale: order.value.locale }
    })
  }

  shoppingCart.clear()
  loading.value = false
})
</script>
