import { createApp } from 'vue'
import * as Sentry from '@sentry/vue'
const app = createApp(App)

// Shoelace and custom css
import '@/plugins/shoelace'
import '@/assets/css/application.css'

import { createHead } from '@unhead/vue'
// Pinia
import { createPinia } from 'pinia'

import App from '@/entrypoints/Application.vue'
import { setupI18n } from '@/i18n.ts'
import en from '@/locales/en.json'
import { localizedPath } from '@/plugins/localized_path'
import { setupRouter } from '@/router/application_routes.js'

const Pinia = createPinia()

const i18n = setupI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: { en }
})

const router = setupRouter(i18n)

const head = createHead()

// Global variables
app.config.globalProperties.$localizedPath = localizedPath
app.provide('i18n', i18n)

// Sentry
Sentry.init({
  app,
  dsn: 'https://b40bbfc787b8fe85e9dd570c9075b22f@o4506514637783040.ingest.us.sentry.io/4507889554030592',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/printplus\.app\/api/],
  environment: import.meta.env.MODE,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

app.use(i18n).use(router).use(Pinia).use(head).mount('#app')
