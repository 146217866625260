<template>
  <header class="absolute inset-x-0 z-50">
    <div class="mx-auto max-w-7xl p-6">
      <nav class="flex items-center justify-between lg:justify-start">
        <router-link v-if="printer === 'all'" :to="{ name: 'root_path' }">
          <span class="sr-only">PrintPlus</span>
          <img alt="PrintPlus" class="h-8 w-auto" src="@/assets/images/logo.png" />
        </router-link>

        <router-link v-if="printer === 'opt'" :to="{ name: 'products_path' }">
          <span class="sr-only">OPT OnDemand | PrintPlus</span>
          <img alt="PrintPlus" class="h-8 w-auto" src="@/assets/images/opt_logo_wide.png" />
        </router-link>

        <button class="rounded-md text-gray-700 lg:hidden" type="button" @click="menuOpened = true">
          <span class="sr-only">Open main menu</span>
          <svg
            aria-hidden="true"
            class="h-6 w-6"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
          >
            <path d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </button>
        <div v-if="printer === 'all'" class="hidden lg:ml-12 lg:flex lg:gap-x-14">
          <router-link
            class="text-sm font-semibold leading-6 text-gray-900"
            :to="$localizedPath({ name: 'root_path' })"
          >
            {{ $t('header.product') }}
          </router-link>
          <router-link
            class="text-sm font-semibold leading-6 text-gray-900"
            :to="$localizedPath({ name: 'features_path' })"
          >
            {{ $t('header.features') }}
          </router-link>
          <router-link
            class="text-sm font-semibold leading-6 text-gray-900"
            :to="$localizedPath({ name: 'pricing_path' })"
          >
            {{ $t('header.pricing') }}
          </router-link>
          <router-link
            class="text-sm font-semibold leading-6 text-gray-900"
            :to="$localizedPath({ name: 'tutorial_path' })"
          >
            {{ $t('header.how_it_works') }}
          </router-link>
        </div>
        <div v-if="!hideCta" class="hidden lg:flex lg:flex-1 lg:justify-end">
          <a
            v-if="printer === 'all'"
            class="-m-2 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            href="https://apps.shopify.com/printplus"
            target="_blank"
          >
            {{ $t('header.install_shopify_app') }}
          </a>

          <div v-if="printer === 'opt'" class="hidden lg:ml-12 lg:flex lg:gap-x-14">
            <router-link
              class="text-sm font-semibold leading-6 text-gray-900"
              :to="$localizedPath({ name: 'pricing_path' })"
            >
              {{ $t('header.pricing') }}
            </router-link>
            <router-link
              class="text-sm font-semibold leading-6 text-gray-900"
              :to="$localizedPath({ name: 'shipping_countries_path' })"
            >
              {{ $t('footer.shipping_countries') }}
            </router-link>
            <router-link
              class="text-sm font-semibold leading-6 text-gray-900"
              :to="$localizedPath({ name: 'faq_path' })"
            >
              {{ $t('footer.faq') }}
            </router-link>

            <router-link
              class="text-sm font-semibold leading-6 text-gray-900 flex items-center gap-2 ml-2"
              :to="$localizedPath({ name: 'shopping_cart_path' })"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-6 w-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
                />
              </svg>
            </router-link>
          </div>

          <!-- <div
            v-if="$route.name !== 'shopping_cart_path' && $route.name !== 'order_path'"
            class="ml-4 flow-root text-sm lg:relative lg:ml-8"
          >
            <router-link
              aria-expanded="false"
              class="group -m-2 flex items-center p-2"
              :to="$localizedPath({ name: 'shopping_cart_path' })"
            >
              <svg
                aria-hidden="true"
                class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-gray-500"
                fill="none"
                stroke="currentColor"
                stroke-width="1.5"
                viewBox="0 0 24 24"
              >
                <path
                  d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span
                class="ml-2 flex h-5 w-5 items-center justify-center rounded-full text-xs font-medium"
                :class="shoppingCart.totalQuantity() > 0 ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-500'"
              >
                {{ shoppingCart.totalQuantity() }}
              </span>
              <span class="sr-only">items in cart, view bag</span>
            </router-link>
          </div> -->
        </div>
      </nav>
    </div>

    <div v-show="menuOpened" aria-modal="true" class="lg:hidden" role="dialog">
      <div
        class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white p-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
      >
        <div class="flex items-center justify-between">
          <router-link class="" :to="{ name: 'root_path' }">
            <span class="sr-only">PrintPlus</span>
            <img alt="PrintPlus" class="h-8 w-auto" src="@/assets/images/logo.png" />
          </router-link>
          <button class="rounded-md text-gray-700" type="button" @click="menuOpened = false">
            <span class="sr-only">Close menu</span>
            <svg
              aria-hidden="true"
              class="h-6 w-6"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              viewBox="0 0 24 24"
            >
              <path d="M6 18L18 6M6 6l12 12" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </button>
        </div>
        <div class="flow-root">
          <div class="divide-y divide-gray-500/10">
            <div class="space-y-2 py-6">
              <router-link
                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                :to="{ name: 'root_path' }"
              >
                {{ $t('header.product') }}
              </router-link>
              <router-link
                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                :to="{ name: 'features_path' }"
              >
                {{ $t('header.features') }}
              </router-link>
              <router-link
                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                :to="{ name: 'pricing_path' }"
              >
                {{ $t('header.pricing') }}
              </router-link>
              <router-link
                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                :to="{ name: 'tutorial_path' }"
              >
                {{ $t('header.how_it_works') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

const menuOpened = ref(false)

withDefaults(
  defineProps<{
    hideCta?: boolean
    printer?: string
  }>(),
  {
    hideCta: false,
    printer: 'all'
  }
)
</script>
