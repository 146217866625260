<template>
  <ul class="divide-y divide-gray-300" role="list">
    <li v-for="lineItems in groupedLineItems" :key="lineItems[0].product_variant_id" class="flex py-4 sm:py-6">
      <div class="shrink-0">
        <img
          v-if="lineItems[0].preview"
          class="h-24 w-24 rounded-md object-cover object-center sm:h-40 sm:w-40"
          :src="lineItems[0].preview"
        />
        <div
          v-else
          class="flex h-24 w-24 items-center justify-center rounded-md border bg-gray-50 object-cover object-center sm:h-40 sm:w-40"
        >
          <sl-spinner
            style="font-size: 3rem; --indicator-color: #4f46e5; --track-color: #eee; --track-width: 1px"
          ></sl-spinner>
        </div>
      </div>

      <div class="ml-4 flex flex-1 flex-col justify-between sm:ml-6">
        <div class="relative">
          <div>
            <div class="flex items-center gap-4 text-sm">
              <h3 class="font-medium text-gray-700">
                {{ $t(`product_category_quality.title.${lineItems[0].category}.${lineItems[0].quality}`) }}
              </h3>
              <div class="flex items-center">
                <div
                  class="mr-1 h-3 w-3 rounded-full border"
                  :style="`background-color: ${lineItems[0].hex_color}`"
                ></div>
                <div class="text-gray-500">{{ lineItems[0].color }}</div>
              </div>
            </div>
          </div>

          <div class="mt-4 sm:mt-0 sm:pr-9">
            <div class="absolute right-0 top-0">
              <button
                class="-m-2 inline-flex p-2 text-gray-400 hover:text-gray-500"
                type="button"
                @click="removeLineItems(lineItems)"
              >
                <span class="sr-only">{{ $t('shopping_cart.remove_item') }}</span>
                <RemoveIcon aria-hidden="true" class="h-5 w-5" />
              </button>
            </div>
          </div>

          <div class="mt-4">
            <div v-for="lineItem in lineItems" :key="lineItem.id" class="mt-1 grid grid-cols-3 items-center text-sm">
              <div>{{ $t('shopping_cart.order_item.size') }} {{ lineItem.size }}</div>
              <div class="mx-auto mr-0">
                <label class="sr-only" for="quantity-1">
                  {{ $t('shopping_cart.order_item.quantity') }},
                  {{ $t(`product_quality_levels.${lineItem.quality}`) }}
                  {{ $t(`style_categories.${lineItem.category}.singular`) }}
                </label>
                <sl-input
                  class="w-16"
                  size="small"
                  type="number"
                  :value="lineItem.quantity"
                  @sl-change="updateQuantity(lineItem, Number($event.target.value))"
                />
              </div>
              <div class="text-right">
                <span class="font-medium"><Price :amount="lineItem.price" /></span>
              </div>
            </div>
          </div>

          <div
            class="mt-6 cursor-pointer text-right text-sm text-indigo-600 underline"
            @click="$emit('addMoreItems', lineItems[0])"
          >
            + {{ $t('shopping_cart.add_more_sizes_and_colors') }}
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import _ from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import RemoveIcon from '@/components/icons/remove.vue'
import Price from '@/components/shared/Price.vue'
import { useShoppingCart } from '@/stores/shopping_cart'
import { ShoppingCartLineItem } from '@/types'

const i18n = useI18n()
const shoppingCart = useShoppingCart()
const loading = ref(true)

onMounted(async () => {
  shoppingCart.checkPreviews()
  loading.value = false
})

const groupedLineItems = computed(() => {
  return _.groupBy(shoppingCart.lineItems(), (lineItem: ShoppingCartLineItem) => [lineItem.color, lineItem.product_id])
})

function removeLineItems(lineItems: ShoppingCartLineItem[]) {
  if (!confirm(i18n.t('shopping_cart.order_item.confirm_remove'))) return
  shoppingCart.removeLineItems(lineItems)
}

function updateQuantity(lineItem: ShoppingCartLineItem, quantity: number) {
  // TODO: when not removed set to previous quantity
  if (quantity <= 0 && !confirm(i18n.t('shopping_cart.order_item.confirm_remove'))) return
  shoppingCart.updateQuantity(lineItem, quantity)
}
</script>
