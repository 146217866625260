<template>
  <div v-if="product" class="flex gap-8 bg-gray-100 p-8">
    <div class="relative w-8/12">
      <Designer
        v-model="product"
        :editable="false"
        :selected-product-variant="selectedProductVariant"
        :show-variant-selector="false"
        :styles="styles"
      />
    </div>

    <div class="w-4/12">
      <div class="rounded-lg border border-gray-200 bg-white p-6 shadow-sm">
        <div class="pb-1 font-medium">
          {{ $t('product_design.choose_color') }}
          <sl-tooltip :content="$t('product_design.color_tooltip')">
            <sl-icon class="h-4 w-4 text-gray-400" name="question-circle" />
          </sl-tooltip>
        </div>

        <div class="flex flex-wrap items-center gap-1">
          <sl-tooltip v-for="color in colors" :key="color.name" :content="color.name">
            <div
              class="flex h-7 w-7 shrink-0 cursor-pointer items-center justify-center rounded-lg border border-gray-200 hover:shadow [&_sl-icon]:hover:block"
              :style="`background-color: ${color.hex_color}`"
              @click="selectColor(color)"
            >
              <sl-icon
                v-if="selectedColor.name === color.name"
                class="text-xl"
                name="check"
                :style="`color: ${textColorForBackground(color.hex_color)}`"
              />
            </div>
          </sl-tooltip>
        </div>

        <div class="mt-6 pb-1 font-medium">{{ $t('product_design.select_size_and_quantity') }}</div>
        <div
          v-for="variant in variants.filter((variant) => variant.name === selectedColor.name)"
          :key="variant.id"
          class="mx-4 flex items-center justify-between border-b p-2"
        >
          <div>{{ variant.size }}</div>
          <div>
            <sl-input
              v-model="quantities[variant.id]"
              class="w-20"
              min="0"
              size="small"
              step="1"
              type="number"
            ></sl-input>
          </div>
        </div>

        <sl-button class="mt-8 w-full" variant="primary" @click="addToCart()">
          {{ $t('product_design.add_to_cart') }}
        </sl-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash'
import { onMounted, ref, watch } from 'vue'

import ProductService from '@/services/product_service'
import StyleService from '@/services/style_service'
import { useShoppingCart } from '@/stores/shopping_cart'
import { Product, ProductVariant, ShoppingCartLineItem, Style, StyleVariant } from '@/types'
import { variantColorSort } from '@/utils/color'
import { textColorForBackground } from '@/utils/color'
import Designer from '@/views/application/product_design/_designer.vue'

type Color = {
  id: number | string
  name: string
  hex_color: string
}

const props = defineProps<{
  lineItem: ShoppingCartLineItem
}>()

const addedVariants = ref([] as { style: Style; variant: StyleVariant }[])
const shoppingCart = useShoppingCart()
const addingToCart = ref(false)
const product = ref(null as Product | null)
const styles = ref([])
const variants = ref([])
const colors = ref([] as Color[])
const selectedColor = ref(null as Color | null)
const selectedProductVariant = ref(null as ProductVariant | null)
const quantities = ref({} as { [key: string]: number })
const emit = defineEmits(['close'])

watch(
  () => props.lineItem,
  async (_value) => {
    loadProductData()
  }
)

onMounted(async () => {
  if (props.lineItem) {
    loadProductData()
  }
})

async function loadProductData() {
  product.value = await ProductService.publicGet(props.lineItem.product_id)
  const quality = product.value.product_variants[0].style.quality_level
  const category = product.value.product_variants[0].style.style_category.slug
  const catalog = await StyleService.publicCatalogData(category, quality)

  styles.value = catalog.styles.map((style) => {
    // Sort design areas by location name
    return {
      ...style,
      design_areas: style.design_areas //.sort(configuration.designAreaNaturalOrder)
    }
  })

  variants.value = catalog.variants

  colors.value = _.chain(variants.value)
    .sort(variantColorSort) // make the next grouping deterministic if there is hex color variation within the same name
    .groupBy((v: StyleVariant) => v.name)
    .map((variantsByColor: StyleVariant[], name: string): Color => {
      return {
        id: variantsByColor[0].id,
        name: name,
        hex_color: variantsByColor[0].hex_color
      }
    })
    .sort(variantColorSort)
    .value()

  quantities.value = {}
  variants.value.forEach((variant) => {
    quantities.value[variant.id] = 0
  })

  let preselectedColor = colors.value.find((color) => color.name === props.lineItem.color)
  selectColor(preselectedColor)
}

function selectColor(color: Color) {
  selectedColor.value = color
  addedVariants.value = []

  variants.value
    .filter((variant) => variant.name === color.name)
    .forEach((variant) => {
      let style = styles.value.find((style) => style.id === variant.style_id)
      addedVariants.value.push({ style, variant })
    })

  if (addedVariants.value) {
    product.value.product_variants = product.value.product_variants.filter((pv) =>
      addedVariants.value.map((v) => v.variant.id).includes(pv.variant.id)
    )
  }

  product.value.product_variants = product.value.product_variants.concat(addedVariants.value)
  selectedProductVariant.value = addedVariants.value[0]
}

async function addToCart() {
  addingToCart.value = true

  // return if there are no quantities
  if (!Object.values(quantities.value).find((quantity) => quantity > 0)) {
    addingToCart.value = false
    return
  }

  const createdProduct = await ProductService.addVariant(
    product.value.id,
    addedVariants.value.map((v) => v.variant.id)
  )

  let shoppingCartLineItems = [] as ShoppingCartLineItem[]
  const variantIds = Object.keys(quantities.value)

  variantIds.forEach((variantId: string) => {
    const quantity = quantities.value[variantId]
    if (quantity > 0) {
      const productVariant = createdProduct.product_variants.find(
        (pv) => pv.variant.id.toString() === variantId.toString()
      )

      shoppingCartLineItems.push({
        product_id: createdProduct.id,
        product_variant_id: productVariant.id,
        variant_id: variantId,
        quantity: quantity,
        category: productVariant.style.style_category.slug,
        quality: productVariant.style.quality_level,
        color: productVariant.variant.name,
        hex_color: productVariant.variant.hex_color,
        size: productVariant.variant.size
      })
    }
  })

  if (shoppingCartLineItems.length === 0) {
    addingToCart.value = false
    return
  }

  shoppingCart.addLineItems(shoppingCartLineItems)
  shoppingCart.checkPreviews(shoppingCartLineItems)

  variants.value.forEach((variant) => {
    quantities.value[variant.id] = 0
  })
  emit('close')
}
</script>
