<template>
  <g class="cursor-nwse-resize opacity-0 group-hover:opacity-100">
    <g fill="none" fill-rule="evenodd">
      <circle cx="16" cy="16" fill="#fff" r="16" />
      <g
        stroke="#000"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        transform="matrix(-1 0 0 1 23 9)"
      >
        <path d="m9.33333333 0h4.66666667v4.66666667" />
        <path d="m4.66666667 14h-4.66666667v-4.66666667" />
        <path d="m14 0-5.444444 5.444444" />
        <path d="m0 14 5.444444-5.444444" />
      </g>
    </g>
  </g>
</template>
