<template>
  <div class="sm:-mx-64">
    <DecorativeGrid />
    <DecorativeGradients />

    <div class="relative">
      <div class="mx-auto max-w-7xl">
        <div class="relative pt-14 lg:w-full lg:max-w-2xl">
          <div class="relative z-30 py-20 sm:px-6 sm:py-40 lg:py-56 lg:pr-0">
            <div class="mx-auto max-w-2xl sm:ml-64 lg:ml-32 lg:max-w-xl xl:ml-0">
              <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                {{ $t('homepage.hero.title') }}
              </h1>
              <p class="mt-6 text-lg leading-8 text-gray-600">
                {{ $t('homepage.hero.subtitle_1') }}
                <br />
                {{ $t('homepage.hero.subtitle_2') }}
              </p>
              <div class="mt-10 flex items-center gap-x-6">
                <a
                  class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  href="https://apps.shopify.com/printplus"
                >
                  {{ $t('homepage.hero.cta_button') }}
                </a>
                <router-link
                  class="text-sm font-semibold leading-6 text-gray-900"
                  :to="$localizedPath({ name: 'features_path' })"
                >
                  {{ $t('homepage.hero.learn_more') }}
                  <span aria-hidden="true">→</span>
                </router-link>
              </div>
            </div>
          </div>

          <svg
            aria-hidden="true"
            class="absolute inset-y-0 right-8 z-10 hidden h-full w-80 translate-x-1/2 fill-white lg:block"
            preserveAspectRatio="none"
            viewBox="0 0 100 100"
          >
            <polygon points="0,0 90,0 50,100 0,100" />
          </svg>
        </div>
      </div>

      <div class="relative z-0 -mx-8 sm:-mx-0 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img alt="" class="h-full w-full object-cover" src="@/assets/images/hero.jpg" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

import DecorativeGradients from '@/components/shared/DecorativeGradients.vue'
import DecorativeGrid from '@/components/shared/DecorativeGrid.vue'

const menuOpened = ref(false)
</script>
