<template>
  <span>
    <span v-if="currency.symbol_position === 'after'">
      {{ Number(amount).toFixed(fractionDigits).replace(/\.0+$/, '') }}
      {{ currency.symbol }}
    </span>
    <span v-else>{{ currency.symbol }}{{ Number(amount).toFixed(fractionDigits).replace(/\.0+$/, '') }}</span>
  </span>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { UserSession } from '@/stores/user_session'
import { Currency } from '@/types'

const userSession = UserSession()

const props = withDefaults(
  defineProps<{
    currency?: Currency
    amount: number | string
    fractionDigits?: number
  }>(),
  { fractionDigits: 2, currency: null }
)

const currency = computed(() => {
  return props.currency || userSession.currentCurrency
})
</script>
