<template>
  <div v-if="selectedProductVariant?.variant" class="flex w-full gap-8">
    <DesignAreaSelector
      v-model="selectedDesignArea"
      class="self-start"
      :design-areas="selectedProductVariant?.style.design_areas.sort(configuration.designAreaNaturalOrder)"
    />

    <div class="w-full">
      <DesignAdjustment
        :additional-text="hasDesign ? additionalPriceText : null"
        class="aspect-square w-full max-w-[650px] overflow-clip rounded-lg border border-gray-200 bg-white shadow-sm"
        :design-area="selectedDesignArea"
        :editable="editable"
        :product-design="productDesign"
        :product-style-design="productStyleDesign"
        :variant="selectedProductVariant.variant"
        @update="onDesignUpdate"
      />

      <ProductVariantSelector
        v-if="showVariantSelector"
        v-model="selectedProductVariant"
        :design-area="selectedDesignArea"
        :product-variants="modelValue.product_variants"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import { useShoppingCart } from '@/stores/shopping_cart'
import { UserSession } from '@/stores/user_session'
import { Configuration } from '@/stores/admin/configuration'
import { Product, ProductDesign, ProductStyleDesign, ProductVariant, Style, StyleDesignArea } from '@/types'
import DesignAdjustment from '@/views/application/product_design/_design_adjustment.vue'
import DesignAreaSelector from '@/views/application/product_design/_design_area_selector.vue'
import ProductVariantSelector from '@/views/application/product_design/_product_variant_selector.vue'

const props = withDefaults(
  defineProps<{
    modelValue: Product
    styles: Style[]
    editable: boolean
    showVariantSelector: boolean
    selectedProductVariant: ProductVariant
  }>(),
  { editable: false, showVariantSelector: true, selectedProductVariant: null }
)

const emit = defineEmits(['update:modelValue'])
const route = useRoute()
const shoppingCart = useShoppingCart()
const userSession = UserSession()
const configuration = Configuration()
const selectedDesignArea = ref(null as StyleDesignArea)
const selectedProductVariant = ref(null as ProductVariant)

onMounted(async () => {
  selectedProductVariant.value =
    props.selectedProductVariant ||
    props.modelValue.product_variants.find((pv) => pv.id?.toString() === route.query.productVariantId) ||
    props.modelValue.product_variants[0]
  selectedDesignArea.value = selectedProductVariant.value.style.design_areas.sort(
    configuration.designAreaNaturalOrder
  )[0]
})

watch(
  () => props.modelValue.product_variants,
  (newValue) => {
    if (props.selectedProductVariant) {
      selectedProductVariant.value = props.selectedProductVariant
    } else if (!newValue.find((pv) => pv.variant.id === selectedProductVariant.value.variant.id)) {
      selectedProductVariant.value = newValue[0]
    }
  }
)

// Get product style design for the selected design area. As we could have multiple styles pick the first one
// that will represent the design area
const productStyleDesign = computed(() => {
  return props.modelValue.product_style_designs.find(
    (psd) =>
      psd.style_id === selectedProductVariant.value.style.id && psd.location === selectedDesignArea.value.location
  )
})

// Get product design for the selected design area
const productDesign = computed(() => {
  return props.modelValue.product_designs.find((pd) => pd.location === selectedDesignArea.value.location)
})

const hasDesign = computed(() => {
  return props.modelValue.product_designs.length > 0
})

const additionalPriceText = computed(() => {
  if (userSession.currentCurrency.symbol_position == 'before') {
    return `+${userSession.currentCurrency.symbol}${
      selectedProductVariant.value.style.pricing?.additional[userSession.currentCurrency.code]
    }`
  } else {
    return `+${selectedProductVariant.value.style.pricing?.additional[userSession.currentCurrency.code]} ${
      userSession.currentCurrency.symbol
    }`
  }
})

// Whenever we upload new image or change it's position it will trigger this event
function onDesignUpdate(event: { product_design: ProductDesign; product_style_design: ProductStyleDesign }) {
  // Remove existing product style designs and product designs for this location
  let product_style_designs = props.modelValue.product_style_designs.filter(
    (psd) => psd.location !== selectedDesignArea.value.location
  )
  let product_designs = props.modelValue.product_designs.filter(
    (pd) => pd.location !== selectedDesignArea.value.location
  )

  // Add new product style design for the location if it was provided
  if (event.product_style_design) {
    // As we can have multiple styles with the same design area we need to add product style design for each style
    const uniqueStyles = [...new Map(props.styles.map((style) => [style.id, style])).values()]
    uniqueStyles.map((style) => {
      product_style_designs = product_style_designs.concat([
        {
          id: event.product_style_design.id,
          style_id: style.id,
          product_id: props.modelValue.id,
          location: selectedDesignArea.value.location,
          x_offset: event.product_style_design.x_offset,
          y_offset: event.product_style_design.y_offset,
          width: event.product_style_design.width,
          height: event.product_style_design.height
        }
      ])
    })
  }

  // Add new product design for the location if it was provided
  if (event.product_design) {
    product_designs = product_designs.concat([event.product_design])
  }

  // TODO: call only when product_design has changed?
  shoppingCart.quote()

  // Update product by adding new product style designs and product designs
  emit('update:modelValue', {
    ...props.modelValue,
    product_style_designs: product_style_designs,
    product_designs: product_designs
  })
}
</script>
