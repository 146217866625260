<template>
  <div class="-mx-12 bg-gray-700 sm:-mx-64">
    <div class="mx-auto max-w-7xl p-12 sm:py-20 lg:flex lg:items-center lg:justify-between">
      <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">
        {{ $t('homepage.cta.title_1') }}
        <br />
        {{ $t('homepage.cta.title_2') }}
      </h2>
      <div class="mt-10 flex items-center gap-x-6 lg:mt-0 lg:shrink-0">
        <a
          class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#115;&#117;&#112;&#112;&#111;&#114;&#116;&#64;&#112;&#114;&#105;&#110;&#116;&#112;&#108;&#117;&#115;&#46;&#97;&#112;&#112;"
        >
          {{ $t('homepage.cta.cta_button') }}
        </a>
      </div>
    </div>
  </div>
</template>
