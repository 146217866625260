<template>
  <div class="bg-white">
    <div class="-mx-6 max-w-7xl px-6 py-24 sm:-mx-0 sm:py-32 lg:px-8 lg:py-40">
      <div class="mx-auto max-w-4xl divide-y divide-gray-900/10">
        <h2 class="text-2xl font-bold leading-10 tracking-tight text-gray-900">{{ $t('faq.title') }}</h2>
        <dl class="mt-10 space-y-6 divide-y divide-gray-900/10">
          <div v-for="faq in faqs" :key="faq" class="pt-6">
            <dt>
              <button
                aria-controls="faq-0"
                aria-expanded="false"
                class="flex w-full items-start justify-between text-left text-gray-900"
                type="button"
              >
                <span class="text-base font-semibold leading-7" @click="faq.expanded = !faq.expanded">
                  {{ faq.question }}
                </span>
                <span class="ml-6 flex h-7 items-center">
                  <svg
                    aria-hidden="true"
                    class="h-6 w-6"
                    :class="{ 'hidden': faq.expanded }"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="1.5"
                    viewBox="0 0 24 24"
                    @click="faq.expanded = !faq.expanded"
                  >
                    <path d="M12 6v12m6-6H6" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <svg
                    aria-hidden="true"
                    class="h-6 w-6"
                    :class="{ 'hidden': !faq.expanded }"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="1.5"
                    viewBox="0 0 24 24"
                    @click="faq.expanded = !faq.expanded"
                  >
                    <path d="M18 12H6" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </span>
              </button>
            </dt>
            <dd v-show="faq.expanded" id="faq-0" class="mt-2 pr-12">
              <p class="text-base leading-7 text-gray-600">
                {{ faq.answer }}
              </p>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const i18n = useI18n()
const numberOfQuestions = 4
const faqs = ref(
  [...Array(numberOfQuestions).keys()].map((i) => {
    return {
      question: i18n.t(`faq.question_${i + 1}`),
      answer: i18n.t(`faq.answer_${i + 1}`)
    }
  })
)
</script>
