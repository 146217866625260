<template>
  <div class="w-full py-8 sm:py-16 bg-gray-100">
    <div class="mx-auto mb-20 max-w-7xl p-6">
      <Breadcrumbs
        :breadcrumbs="
          breadcrumbs
            .concat({
              name: $t(`style_categories.${category}.plural`),
              link: 'product_category_path',
              params: { category: category }
            })
            .concat({ name: $t(`product_quality_levels.${quality}`) })
        "
      />
      <div v-if="loading" class="mt-40 flex w-full justify-center">
        <sl-spinner style="font-size: 5rem; --indicator-color: #4f46e5; --track-color: #eee"></sl-spinner>
      </div>
      <div v-else class="mt-4 flex">
        <div class="relative w-8/12">
          <Designer v-model="product" :editable="true" :show-variant-selector="false" :styles="styles" />
        </div>

        <div class="w-4/12">
          <div class="p-6 rounded-lg border border-gray-200 bg-white shadow-sm">
            <div class="flex items-center gap-2 pb-1 font-medium">
              {{ $t('product_design.choose_color') }}
              <sl-tooltip :content="$t('product_design.color_tooltip')">
                <sl-icon class="h-4 w-4 text-gray-400" name="question-circle" />
              </sl-tooltip>
            </div>

            <div class="flex flex-wrap items-center gap-1 mt-2">
              <sl-tooltip v-for="color in colors" :key="color.name" :content="color.name">
                <div
                  class="flex h-7 w-7 shrink-0 cursor-pointer items-center justify-center rounded-lg border shadow-xs border-gray-100 hover:shadow [&_sl-icon]:hover:block"
                  :style="`background-color: ${color.hex_color}`"
                  @click="toggleColor(color)"
                >
                  <sl-icon
                    v-if="selectedColor.name === color.name"
                    class="text-xl"
                    name="check"
                    :style="`color: ${textColorForBackground(color.hex_color)}`"
                  />
                </div>
              </sl-tooltip>
            </div>

            <div class="mt-6 pb-1 font-medium">{{ $t('product_design.select_size_and_quantity') }}</div>
            <div
              v-for="(variant, index) in variants
                .filter((variant) => variant.name === selectedColor.name)
                .sort((a, b) => sizeSort[a.size] - sizeSort[b.size])"
              :key="variant.id"
              class="mx-1 flex items-center justify-between border-gray-100 p-1"
              :class="{ 'border-t': index > 0 }"
            >
              <div>{{ variant.size }}</div>
              <div>
                <sl-input v-model="quantities[variant.id]" class="w-20" min="0" size="small" type="number"></sl-input>
              </div>
            </div>
          </div>

          <div class="p-6 rounded-lg border border-gray-200 bg-white shadow-sm mt-4">
            <div class="flex justify-between items-center">
              <div>{{ $t('product_design.production_cost') }}</div>
              <div class="font-semibold">€{{ productionCost }}</div>
            </div>

            <sl-button
              class="mt-6 w-full"
              :disabled="!hasDesign || !hasQuantities"
              :loading="addingToCart"
              variant="primary"
              @click="addToCart"
            >
              {{ $t('product_design.add_to_cart') }}
            </sl-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import Breadcrumbs from '@/components/shared/Breadcrumbs.vue'
import { localizedPath } from '@/plugins/localized_path'
import ProductService from '@/services/product_service'
import StyleService from '@/services/style_service'
import { useShoppingCart } from '@/stores/shopping_cart'
import { Product, StyleVariant } from '@/types'
import { Breadcrumb, ShoppingCartLineItem } from '@/types'
import { variantColorSort } from '@/utils/color'
import { textColorForBackground } from '@/utils/color'
import Designer from '@/views/application/product_design/_designer.vue'

type Color = {
  id: number | string
  name: string
  hex_color: string
}

const sizeSort = {
  'XS': 0,
  'S': 1,
  'M': 2,
  'L': 3,
  'XL': 4,
  '2XL': 5,
  '3XL': 6,
  '4XL': 7,
  '5XL': 8
}

const shoppingCart = useShoppingCart()
const i18n = useI18n()
const breadcrumbs = [{ name: i18n.t('breadcrumbs.all_products'), link: 'products_path' }] as Breadcrumb[]
const route = useRoute()
const router = useRouter()
const loading = ref(true)
const addingToCart = ref(false)
const styles = ref([])
const variants = ref([])
const colors = ref([] as Color[])
const selectedColor = ref(null as Color | null)
const quantities = ref({} as { [key: string]: number })
const product = ref({
  id: null,
  title: `${i18n.t(`product_quality_levels.${route.params.quality}`)} ${i18n.t(
    `style_categories.${route.params.category}.singular`
  )}`,
  description: `${i18n.t(`product_quality_levels.${route.params.quality}`)} ${i18n.t(
    `style_categories.${route.params.category}.singular`
  )}`,
  available_until: null,
  draft: true,
  fulfilment_autopilot: 'speed',
  fulfilment_period: null,
  fulfilment_units: null,
  fulfilment_limit: null,
  product_variants: [],
  product_designs: [],
  product_style_designs: [],
  created_at: null
} as Product)

const category = computed(() => {
  return route.params.category as string
})

const quality = computed(() => {
  return route.params.quality as string
})

const hasDesign = computed(() => {
  return product.value.product_designs.length > 0
})

const hasQuantities = computed(() => {
  return Object.values(quantities.value).find((quantity) => quantity > 0)
})

onMounted(async () => {
  const catalog = await StyleService.publicCatalogData(route.params.category as string, route.params.quality as string)

  styles.value = catalog.styles.map((style) => {
    // Sort design areas by location name
    return {
      ...style,
      design_areas: style.design_areas //.sort(configuration.designAreaNaturalOrder)
    }
  })

  variants.value = catalog.variants

  colors.value = _.chain(variants.value)
    .sort(variantColorSort) // make the next grouping deterministic if there is hex color variation within the same name
    .groupBy((v: StyleVariant) => v.name)
    .map((variantsByColor: StyleVariant[], name: string): Color => {
      return {
        id: variantsByColor[0].id,
        name: name,
        hex_color: variantsByColor[0].hex_color
      }
    })
    .sort(variantColorSort)
    .value()

  if (route.params.productId) {
    product.value = await ProductService.publicGet(route.params.productId as string)
  } else {
    toggleColor(colors.value[0])
  }
  loading.value = false
})

function toggleColor(color: Color) {
  selectedColor.value = color
  quantities.value = {}
  let currentVariants = []

  variants.value
    .filter((variant) => variant.name === color.name)
    .forEach((variant) => {
      let style = styles.value.find((style) => style.id === variant.style_id)
      currentVariants.push({ style, variant })
    })

  currentVariants.forEach((product_variant) => {
    quantities.value[product_variant.variant.id] = 0
  })

  product.value.product_variants = currentVariants
}

async function addToCart() {
  addingToCart.value = true

  // return if there are no quantities
  if (!Object.values(quantities.value).find((quantity) => quantity > 0)) {
    addingToCart.value = false
    return
  }

  const createdProduct = await ProductService.publicCreate(product.value)

  let shoppingCartLineItems = [] as ShoppingCartLineItem[]
  const variantIds = Object.keys(quantities.value)

  variantIds.forEach((variantId: string) => {
    const quantity = quantities.value[variantId]
    if (quantity > 0) {
      const productVariant = createdProduct.product_variants.find(
        (pv) => pv.variant.id.toString() === variantId.toString()
      )

      shoppingCartLineItems.push({
        product_id: createdProduct.id,
        product_variant_id: productVariant.id,
        variant_id: variantId,
        quantity: quantity,
        category: productVariant.style.style_category.slug,
        quality: productVariant.style.quality_level,
        color: productVariant.variant.name,
        hex_color: productVariant.variant.hex_color,
        size: productVariant.variant.size
      })
    }
  })

  if (shoppingCartLineItems.length === 0) {
    addingToCart.value = false
    return
  }

  shoppingCart.addLineItems(shoppingCartLineItems)

  router.push(localizedPath({ name: 'shopping_cart_path' }, i18n.locale.value))
  addingToCart.value = false
}

const productionCost = computed(() => {
  const firstDesign = _.max(styles.value[0].pricing?.breakpoints.map((bp) => bp[2]['eur']))
  const additionalDesigns =
    product.value.product_designs.length > 0
      ? styles.value[0].pricing?.additional['eur'] * (product.value.product_designs.length - 1)
      : 0
  return ((firstDesign + additionalDesigns) * _.sum(Object.values(quantities.value))).toFixed(2)
})
</script>
