<template>
  <svg
    aria-hidden="true"
    class="absolute -top-[40rem] left-1 z-0 h-[64rem] w-[175.5rem] -translate-x-1/2 stroke-gray-900/20 [mask-image:radial-gradient(64rem_64rem_at_111.5rem_0%,white,transparent)]"
  >
    <defs>
      <pattern id="e87443c8-56e4-4c20-9111-55b82fa704e3" height="200" patternUnits="userSpaceOnUse" width="200">
        <path d="M0.5 0V200M200 0.5L0 0.499983" />
      </pattern>
    </defs>
    <rect fill="url(#e87443c8-56e4-4c20-9111-55b82fa704e3)" height="100%" stroke-width="0" width="100%" />
  </svg>
</template>
