<template>
  <nav aria-label="Breadcrumb" class="flex">
    <ol class="flex items-center space-x-4" role="list">
      <li v-for="(breadcrumb, index) in breadcrumbs" :key="breadcrumb.name">
        <div class="flex items-center">
          <sl-icon v-if="index > 0" class="h-4 w-4 shrink-0 text-gray-400" name="chevron-right"></sl-icon>
          <router-link
            v-if="breadcrumb.link"
            class="font-medium text-gray-500 hover:text-gray-700"
            :class="[{ 'ml-4': index > 0 }, textSize]"
            :to="$localizedPath({ name: breadcrumb.link, params: breadcrumb.params })"
          >
            {{ breadcrumb.name }}
          </router-link>
          <div v-else class="font-medium text-gray-700" :class="[{ 'ml-4': index > 0 }, textSize]">
            {{ breadcrumb.name }}
          </div>
        </div>
      </li>
    </ol>
  </nav>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { Breadcrumb } from '@/types'

const props = defineProps<{
  breadcrumbs: Breadcrumb[]
  size?: 'small' | 'medium' | 'large'
}>()

const textSize = computed(() => {
  switch (props.size) {
    case 'small':
      return 'text-sm'
    case 'medium':
      return 'text-base'
    case 'large':
      return 'text-lg'
    default:
      return 'text-sm'
  }
})
</script>
