<template>
  <div class="mx-auto overflow-hidden font-sans antialiased">
    <div class="max-w-prose px-6 py-32 text-base leading-7 text-gray-700 lg:px-8">
      <p class="text-base font-semibold leading-7 text-indigo-600">Last updated: August 14, 2023</p>
      <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Privacy Policy</h1>
      <p class="mt-6 text-xl leading-8">
        This privacy policy outlines how Useful Collective s.r.o. (&ldquo;we&rdquo;, &ldquo;us&rdquo;, or
        &ldquo;our&rdquo;) collects, uses, and processes any personal data that we receive from you, or you provide to
        us, in connection with your use of
        <a class="text-indigo-600" href="https://printplus.app">our website</a>
        and our Shopify app (together the Site). By accessing or using our Site, you agree with the terms set out in
        this Privacy Policy.
      </p>

      <p class="mt-6">
        If you are a registered Site user or a visitor to our website we act as the
        <em>&ldquo;data controller&rdquo;</em>
        of personal data. This means we determine how and why your data are processed.
      </p>

      <p class="mt-6">
        For your customers who are just making a purchase of your goods through us, but are not registered users, we act
        as the
        <em>&ldquo;data processor&rdquo;</em>
        for you, the merchant: this means we only process their data to help us provide our service to you, or in
        accordance with your instructions, or as required by law. You are responsible for making sure that their
        personal data is treated in accordance with applicable data protection laws. That includes informing them how
        service providers (like us) collect and use data on your behalf.
      </p>

      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Information We Collect</h2>

      <p class="mt-6">
        When you visit the Site, we automatically collect certain information about your device, including information
        about your web browser, IP address, time zone, and some of the cookies that are installed on your device.
        Additionally, as you use the Site, we collect information about the individual web pages or products that you
        view, what websites or search terms referred you to the Site, and information about how you interact with the
        Site. We refer to this automatically-collected information as
        <strong>&ldquo;Device Information.&rdquo;</strong>
      </p>

      <p class="mt-6">We collect Device Information including, but not limited to, the following technologies:</p>

      <ul class="mt-8 max-w-xl space-y-8 text-gray-600" role="list">
        <li class="flex gap-x-3">
          <svg
            class="mt-1 h-5 w-5 flex-none text-indigo-600"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M5.25 12a.75.75 0 01.75-.75h12a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75z"
              fill-rule="evenodd"
            />
          </svg>

          <span>
            <strong class="font-semibold text-gray-900">Cookies</strong>
            are data files that are placed on your device or computer and often include an anonymous unique identifier.
            For more information about cookies, and how to disable cookies, visit
            <a class="font-semibold text-indigo-600" href="http://www.allaboutcookies.org">
              http://www.allaboutcookies.org
            </a>
            .
          </span>
        </li>
        <li class="flex gap-x-3">
          <svg
            class="mt-1 h-5 w-5 flex-none text-indigo-600"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M5.25 12a.75.75 0 01.75-.75h12a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75z"
              fill-rule="evenodd"
            />
          </svg>

          <span>
            <strong class="font-semibold text-gray-900">Log files</strong>
            track actions occurring on the Site, and collect data including your IP address, browser type, Internet
            service provider, referring/exit pages, and date/time stamps.
          </span>
        </li>
      </ul>

      <p class="mt-6">
        Additionally when you create an account on the Site or connect our Site to your Shopify account. We collect your
        name, email address, any data that identifies you, artwork that you upload, and the data from your content and
        products you have designed. We refer to this information as
        <strong>&ldquo;Store Information.&rdquo;</strong>
      </p>

      <p class="mt-6">
        When your end customers place orders, we collect the sales information needed to fulfill such orders. Including
        their name, email address, and shipping address. We refer to this information as
        <strong>&ldquo;Orders information.&rdquo;</strong>
      </p>

      <p class="mt-6">
        When we talk about &ldquo;Personal Information&rdquo; in this Privacy Policy, we are talking about Device
        Information, Store Information and Orders Information collectively.
      </p>

      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">How We Use Your Information</h2>

      <p class="mt-6">We use the personal information we collect for the following purposes:</p>

      <ul class="mt-8 max-w-xl space-y-8 text-gray-600" role="list">
        <li class="flex gap-x-3">
          <svg
            class="mt-1 h-5 w-5 flex-none text-indigo-600"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M5.25 12a.75.75 0 01.75-.75h12a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75z"
              fill-rule="evenodd"
            />
          </svg>

          <span>
            <strong class="font-semibold text-gray-900"></strong>
            Communicate with you;
          </span>
        </li>
        <li class="flex gap-x-3">
          <svg
            class="mt-1 h-5 w-5 flex-none text-indigo-600"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M5.25 12a.75.75 0 01.75-.75h12a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75z"
              fill-rule="evenodd"
            />
          </svg>

          <span>
            <strong class="font-semibold text-gray-900"></strong>
            Screen orders for potential risk or fraud;
          </span>
        </li>
        <li class="flex gap-x-3">
          <svg
            class="mt-1 h-5 w-5 flex-none text-indigo-600"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M5.25 12a.75.75 0 01.75-.75h12a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75z"
              fill-rule="evenodd"
            />
          </svg>

          <span>
            <strong class="font-semibold text-gray-900"></strong>
            Process and fulfill orders, including sharing the information with fulfillment partners;
          </span>
        </li>
        <li class="flex gap-x-3">
          <svg
            class="mt-1 h-5 w-5 flex-none text-indigo-600"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M5.25 12a.75.75 0 01.75-.75h12a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75z"
              fill-rule="evenodd"
            />
          </svg>

          <span>
            <strong class="font-semibold text-gray-900"></strong>
            Improve and optimize our Site; and
          </span>
        </li>
        <li class="flex gap-x-3">
          <svg
            class="mt-1 h-5 w-5 flex-none text-indigo-600"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M5.25 12a.75.75 0 01.75-.75h12a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75z"
              fill-rule="evenodd"
            />
          </svg>

          <span>
            <strong class="font-semibold text-gray-900"></strong>
            When in line with the preferences you have shared with us, provide you with information or advertising
            relating to our products or services.
          </span>
        </li>
      </ul>

      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Data Retention</h2>

      <p class="mt-6">
        We retain your personal data for as long as necessary to fulfill the purposes for which it was collected,
        including for the purposes of satisfying any legal, accounting, or reporting requirements. The retention period
        for each category of data is as follows:
      </p>

      <ul class="mt-8 max-w-xl space-y-8 text-gray-600" role="list">
        <li class="flex gap-x-3">
          <svg
            class="mt-1 h-5 w-5 flex-none text-indigo-600"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M5.25 12a.75.75 0 01.75-.75h12a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75z"
              fill-rule="evenodd"
            />
          </svg>

          <span>
            <strong class="font-semibold text-gray-900">Device Information:</strong>
            2 years
          </span>
        </li>
        <li class="flex gap-x-3">
          <svg
            class="mt-1 h-5 w-5 flex-none text-indigo-600"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M5.25 12a.75.75 0 01.75-.75h12a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75z"
              fill-rule="evenodd"
            />
          </svg>

          <span>
            <strong class="font-semibold text-gray-900">Store Information:</strong>
            10 years
          </span>
        </li>
        <li class="flex gap-x-3">
          <svg
            class="mt-1 h-5 w-5 flex-none text-indigo-600"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M5.25 12a.75.75 0 01.75-.75h12a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75z"
              fill-rule="evenodd"
            />
          </svg>

          <span>
            <strong class="font-semibold text-gray-900">Orders Information:</strong>
            10 years
          </span>
        </li>
      </ul>

      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Do Not Track Signals</h2>

      <p class="mt-6">
        We do not alter our site’s data collection and use practices when we see a Do Not Track signal from your
        browser.
      </p>

      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Complaints</h2>

      <p class="mt-6">
        If you have any concerns about our data protection practices, you have the right to file a complaint with the
        Czech data protection authority, the Office for Personal Data Protection, at
        <a class="font-semibold text-indigo-600" href="https://www.uoou.cz">www.uoou.cz</a>
        .
      </p>

      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Changes</h2>

      <p class="mt-6">
        We may update this privacy policy from time to time in order to reflect, for example, changes to our practices
        or for other operational, legal, or regulatory reasons.
      </p>

      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Contact Us</h2>

      <p class="mt-6">
        You have the right to access, rectify, erase, restrict processing, object to processing, and request data
        portability of your personal data. To exercise any of these rights, or for more information about our privacy
        practices, if you have questions, or if you would like to make a complaint, please
        <a
          class="font-semibold text-indigo-600"
          href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#115;&#117;&#112;&#112;&#111;&#114;&#116;&#64;&#112;&#114;&#105;&#110;&#116;&#112;&#108;&#117;&#115;&#46;&#97;&#112;&#112;"
        >
          contact us by email
        </a>
        .
      </p>
    </div>
  </div>
</template>
