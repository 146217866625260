<template>
  <section
    aria-labelledby="summary-heading"
    class="mt-16 rounded-lg bg-gray-100 px-4 py-6 sm:p-6 lg:mt-0 lg:p-8 bg-white shadow-lg"
  >
    <h2 id="summary-heading" class="text-lg font-medium text-gray-900">{{ $t('shopping_cart.summary.title') }}</h2>

    <dl class="mt-6 space-y-4">
      <div class="flex items-center justify-between">
        <dt class="text-sm text-gray-600">{{ $t('shopping_cart.summary.subtotal') }}</dt>
        <dd class="text-sm font-medium text-gray-900">
          <span v-if="shoppingCart.quoting()">
            <sl-spinner
              style="font-size: 0.9rem; --indicator-color: #4f46e5; --track-color: #eee; --track-width: 1px"
            ></sl-spinner>
          </span>
          <span v-else><Price :amount="subtotal" /></span>
        </dd>
      </div>
      <div class="flex items-center justify-between border-t border-gray-200 pt-4">
        <dt class="flex items-center text-sm text-gray-600">
          <span>{{ $t('shopping_cart.summary.shipping') }}</span>
        </dt>
        <dd class="text-sm font-medium text-gray-900">
          <span v-if="shoppingCart.quoting()">
            <sl-spinner
              style="font-size: 0.9rem; --indicator-color: #4f46e5; --track-color: #eee; --track-width: 1px"
            ></sl-spinner>
          </span>
          <span v-else><Price :amount="shoppingCart.shipping()" /></span>
        </dd>
      </div>
      <div class="flex items-center justify-between border-t border-gray-200 pt-4">
        <dt class="text-base font-medium text-gray-900">{{ $t('shopping_cart.summary.total') }}</dt>
        <dd class="text-base font-medium text-gray-900">
          <span v-if="shoppingCart.quoting()">
            <sl-spinner
              style="font-size: 0.9rem; --indicator-color: #4f46e5; --track-color: #eee; --track-width: 1px"
            ></sl-spinner>
          </span>
          <span v-else><Price :amount="total" /></span>
        </dd>
      </div>
    </dl>

    <div class="mt-6">
      <button
        class="w-full rounded-md border border-transparent bg-indigo-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
        @click="$emit('checkout')"
      >
        {{ $t('shopping_cart.summary.checkout') }}
      </button>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import Price from '@/components/shared/Price.vue'
import { useShoppingCart } from '@/stores/shopping_cart'

const shoppingCart = useShoppingCart()

const subtotal = computed(() => {
  return shoppingCart
    .lineItems()
    .reduce((sum, lineItem) => sum + Number(lineItem.price), 0)
    .toFixed(2)
})

const total = computed(() => {
  return (Number(subtotal.value) + Number(shoppingCart.shipping())).toFixed(2)
})
</script>
