<template>
  <div v-if="variant" class="group relative">
    <input ref="file" hidden type="file" @change="onUpload" />
    <svg :id="`preview-${variant.style_id}`" class="w-full" viewBox="0 0 1000 1000">
      <defs>
        <pattern id="light-heather" height="500" patternUnits="userSpaceOnUse" width="500">
          <image height="500" href="https://img.printplus.app/images/heather_light_500.png" width="500" x="0" y="0" />
        </pattern>
        <pattern id="dark-heather" height="500" patternUnits="userSpaceOnUse" width="500">
          <image height="500" href="https://img.printplus.app/images/heather_dark_500.png" width="500" x="0" y="0" />
        </pattern>
      </defs>

      <!-- MOCK -->
      <rect :fill="variant.hex_color" height="1000" width="1000" x="0" y="0" />
      <rect v-if="variant.light_heather" fill="url(#light-heather)" height="100%" width="100%" />
      <rect v-if="variant.dark_heather" fill="url(#dark-heather)" height="100%" width="100%" />
      <image v-if="editable" height="1000" :href="designArea.image" width="1000" />
      <mask v-if="editable" :id="`variant-${variant.id}`">
        <rect fill="#a0a0a0" height="1000" width="1000" x="0" y="0" />
        <rect
          fill="#fff"
          :height="designArea.height"
          :width="designArea.width"
          :x="designArea.x_offset"
          :y="designArea.y_offset"
        />
      </mask>
      <mask v-else :id="`variant-${variant.id}`">
        <rect
          fill="#fff"
          :height="designArea.height"
          :width="designArea.width"
          :x="designArea.x_offset"
          :y="designArea.y_offset"
        />
      </mask>

      <!-- BACKGROUND -->
      <g v-if="designArea.mask">
        <mask id="background-mask">
          <image height="100%" :href="designArea.mask" width="100%" />
        </mask>
        <rect
          class="background-color"
          fill="hsl(0 0% 97.5%)"
          height="1000"
          mask="url(#background-mask)"
          width="1000"
          x="0"
          y="0"
        />
      </g>

      <!-- DESIGN -->
      <g v-if="productDesign.image && adjustedDesign.width" :mask="`url(#variant-${variant.id})`">
        <g
          :class="editable ? 'cursor-grab' : ''"
          :transform="`translate(${adjustedDesign.x_offset + designArea.x_offset},${
            adjustedDesign.y_offset + designArea.y_offset
          })`"
        >
          <svg :id="`design-${variant.style_id}`">
            <image
              :href="productDesign.image"
              :width="adjustedDesign.width"
              @mousedown.prevent="dragMouse($event, 'move')"
            ></image>
          </svg>
        </g>
      </g>

      <image v-if="!editable" height="1000" :href="designArea.image" style="pointer-events: none" width="1000" />

      <!-- PRINTABLE AREA -->
      <template v-if="editable">
        <PrintableArea
          :additional-text="additionalText"
          :class="printableAreaClass"
          :design-area="designArea"
          :product-design="productDesign"
          :variant="variant"
          @[!productDesign.image&&`click`]="changeImage()"
          @[!productDesign.image&&`drop`].prevent="onUpload"
          @dragenter.prevent="printableAreaClass = 'opacity-70 text-blue-500'"
          @dragleave.prevent="printableAreaClass = ''"
          @dragover.prevent
        />

        <!-- ACTIONS -->
        <g
          v-if="productDesign.image && adjustedDesign.width"
          :transform="`translate(${adjustedDesign.x_offset + designArea.x_offset},${
            adjustedDesign.y_offset + designArea.y_offset
          })`"
        >
          <SizeInfo
            :height="designHeightInInches"
            :is-max-allowed-width="isMaxAllowedWidth"
            :transform="`translate(${adjustedDesign.width / 2 - (isMaxAllowedWidth ? 125 : 60)}, -70)`"
            :width="designWidthInInches"
          />

          <ScaleButton
            :transform="`translate(${adjustedDesign.width - 20}, ${adjustedDesign.width * designImageRatio() - 20})`"
            @mousedown.prevent="dragMouse($event, 'resize')"
          />

          <RemoveButton :transform="`translate(${adjustedDesign.width - 20}, -15)`" @click="removeDesign()" />
        </g>
      </template>
    </svg>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, PropType, ref, watch } from 'vue'

import { ProductDesign, ProductStyleDesign, StyleDesignArea, StyleVariant } from '@/types'
import { imageDimensions } from '@/utils/image'
import PrintableArea from '@/views/application/product_design/_printable_area.vue'
import RemoveButton from '@/views/application/product_design/_remove_button.vue'
import ScaleButton from '@/views/application/product_design/_scale_button.vue'
import SizeInfo from '@/views/application/product_design/_size_info.vue'

const emit = defineEmits(['update', 'update:productDesign'])
const props = defineProps({
  productDesign: {
    type: Object as PropType<ProductDesign>,
    default: {} as ProductDesign
  },
  designArea: {
    type: Object as PropType<StyleDesignArea>,
    default: {} as StyleDesignArea
  },
  variant: {
    type: Object as PropType<StyleVariant>,
    default: {} as StyleVariant
  },
  productStyleDesign: {
    type: Object as PropType<ProductStyleDesign>,
    default: {} as ProductStyleDesign
  },
  editable: {
    type: Boolean,
    default: true
  },
  additionalText: {
    type: String,
    default: null
  }
})

const file = ref(null)
const printableAreaClass = ref('')
const adjustedDesign = ref({} as any)
const cursor = ref({
  clientX: 0,
  clientY: 0,
  movementX: 0,
  movementY: 0
})

onMounted(() => {
  adjustedDesign.value = {
    id: props.productStyleDesign?.id,
    x_offset: props.productStyleDesign?.x_offset,
    y_offset: props.productStyleDesign?.y_offset,
    width: props.productStyleDesign?.width,
    height: props.productStyleDesign?.width * designImageRatio(),
    initialWidth: props.productStyleDesign?.width
  }
})

watch(
  () => [props.designArea, props.productStyleDesign],
  (_value) => {
    file.value.value = null
    adjustedDesign.value = {
      id: props.productStyleDesign?.id,
      x_offset: props.productStyleDesign?.x_offset,
      y_offset: props.productStyleDesign?.y_offset,
      width: props.productStyleDesign?.width,
      height: props.productStyleDesign?.width * designImageRatio(),
      initialWidth: props.productStyleDesign?.width
    }
  }
)

// -----------------------------------------------------------------------------
// Helper methods --------------------------------------------------------------
// -----------------------------------------------------------------------------

// Width/height ratio so we can properly position resize button
function designImageRatio(): number {
  return props.productDesign.height / props.productDesign.width
}

const designWidthInInches = computed((): number => {
  return Number(
    ((adjustedDesign.value.width / props.designArea.width) * Number(props.designArea.width_in_inches))
      .toFixed(1)
      .replace(/\.0+$/, '')
  )
})

const designHeightInInches = computed((): number => {
  // this uses width because we don't guarantee square pixels in backend values
  return Number(
    ((adjustedDesign.value.height / props.designArea.width) * Number(props.designArea.width_in_inches))
      .toFixed(1)
      .replace(/\.0+$/, '')
  )
})

const maxAllowedWidth = computed((): number => {
  return (props.productDesign.width / 300) * (props.designArea.width / Number(props.designArea.width_in_inches))
})

const isMaxAllowedWidth = computed((): boolean => {
  return adjustedDesign.value.width === maxAllowedWidth.value
})

// -----------------------------------------------------------------------------
// Handle design movement and resize -------------------------------------------
// -----------------------------------------------------------------------------

function dragMouse(event: MouseEvent, action: string) {
  if (!props.editable) return

  // get the mouse cursor position at startup:
  cursor.value.clientX = event.clientX
  cursor.value.clientY = event.clientY

  if (action === 'move') {
    document.onmousemove = move
  } else if (action === 'resize') {
    document.onmousemove = resize
  }

  document.onmouseup = closeDragElement
}

function move(event: MouseEvent) {
  cursor.value.movementX = cursor.value.clientX - event.clientX
  cursor.value.movementY = cursor.value.clientY - event.clientY
  cursor.value.clientX = event.clientX
  cursor.value.clientY = event.clientY

  adjustedDesign.value.x_offset = Number(adjustedDesign.value.x_offset - cursor.value.movementX * scaleRatio.value)
  adjustedDesign.value.y_offset = Number(adjustedDesign.value.y_offset - cursor.value.movementY * scaleRatio.value)
}

function closeDragElement() {
  document.onmouseup = null
  document.onmousemove = null
  adjustedDesign.value.initialWidth = Number(adjustedDesign.value.width)
  emit('update', { product_style_design: adjustedDesign.value, product_design: props.productDesign })
}

function resize(event: MouseEvent) {
  // resize width first
  adjustedDesign.value.width = Number(
    Math.min(
      // prevent design to be smaller than 0
      Math.max(adjustedDesign.value.initialWidth + (event.clientX - cursor.value.clientX) * scaleRatio.value, 0),
      // allow design to be scaled up to 300dpi
      props.productDesign.extension === 'svg' ? Number.MAX_VALUE : maxAllowedWidth.value
    )
  )

  // resize height proportionally
  adjustedDesign.value.height = adjustedDesign.value.width * designImageRatio()
}

// scale ratio between original preview image and actual size. It's used to calculate how fast to move/resize
// design image when dragging with mouse
const scaleRatio = computed((): number => {
  let img = document.getElementById(`preview-${props.variant.style_id}`) as HTMLImageElement
  return 1000.0 / img.clientWidth
})

// -----------------------------------------------------------------------------
// Handle image upload ---------------------------------------------------------
// -----------------------------------------------------------------------------

function changeImage() {
  file.value.click()
}

function removeDesign() {
  file.value.value = null
  emit('update', { product_style_design: null, product_design: null })
}

function onUpload(event) {
  const files = event.target.files || event.dataTransfer.files
  let reader = new FileReader()
  reader.onload = async (x) => {
    const uploadedImage = x.target.result as any
    if (uploadedImage) {
      const dimensions = await imageDimensions(uploadedImage)
      const contentType = uploadedImage.split(';')[0].split(':')[1]
      const extension = contentType.split('/')[1] == 'svg+xml' ? 'svg' : contentType.split('/')[1]

      const scale = props.designArea.width / Number(props.designArea.width_in_inches)

      emit('update', {
        product_style_design: {
          x_offset: 0,
          y_offset: 0,
          width:
            extension === 'svg'
              ? props.designArea.width
              : Math.min(props.designArea.width, (dimensions.width / 300) * scale),
          height: extension === 'svg' ? props.designArea.height : (dimensions.height / 300) * scale
        },
        product_design: {
          location: props.designArea.location,
          image: uploadedImage,
          width: dimensions.width,
          height: dimensions.height,
          extension: extension
        }
      })
    }
  }
  reader.readAsDataURL(files[0])
}
</script>
