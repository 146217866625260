<template>
  <div class="mx-auto overflow-hidden font-sans antialiased">
    <div class="max-w-prose px-6 py-32 text-base leading-7 text-gray-700 lg:px-8">
      <p class="text-base font-semibold leading-7 text-indigo-600">Last updated: August 1, 2023</p>
      <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Terms and Conditions</h1>
      <p class="mt-6 text-xl leading-8">
        By using the PrintPlus App (&ldquo;Site&rdquo;), you (&ldquo;User&rdquo;) agree to be bound by the following
        Terms and Conditions (&ldquo;Terms&rdquo;). Please read these Terms carefully before using our Site.
      </p>

      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">1. Definitions</h2>

      <ul class="mt-8 max-w-xl space-y-8 text-gray-600" role="list">
        <li class="gap-x-3">
          <strong class="font-semibold text-gray-900">&ldquo;Company&rdquo;</strong>
          refers to Useful Collective s.r.o., its affiliates and licensors.
        </li>
        <li class="gap-x-3">
          <strong class="font-semibold text-gray-900">&ldquo;Products&rdquo;</strong>
          refers to the customized apparel items created by the User using the Site.
        </li>
      </ul>

      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">2. Scope and Limitations of Services</h2>

      <p class="mt-6">
        The Site allows Users to create and add customized apparel products to their Shopify stores. The Company
        reserves the right to impose restrictions on the types of products that can be created using the Site and to
        remove or refuse any products that violate the prohibited content guidelines.
      </p>

      <h3 class="mt-16 text-xl font-bold tracking-tight text-gray-900">2.1 Prohibited Content</h3>

      <p class="mt-6">Users are prohibited from creating and publishing content that:</p>

      <ul class="mt-8 max-w-xl space-y-8 text-gray-600" role="list">
        <li class="flex gap-x-3">
          <svg
            class="mt-1 h-5 w-5 flex-none text-indigo-600"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
              fill-rule="evenodd"
            />
          </svg>

          <span>
            <strong class="font-semibold text-gray-900"></strong>
            Involves child exploitation or harm.
          </span>
        </li>
        <li class="flex gap-x-3">
          <svg
            class="mt-1 h-5 w-5 flex-none text-indigo-600"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
              fill-rule="evenodd"
            />
          </svg>

          <span>
            <strong class="font-semibold text-gray-900"></strong>
            Constitutes harassment, bullying, defamation, or threats to any individual or group.
          </span>
        </li>
        <li class="flex gap-x-3">
          <svg
            class="mt-1 h-5 w-5 flex-none text-indigo-600"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
              fill-rule="evenodd"
            />
          </svg>

          <span>
            <strong class="font-semibold text-gray-900"></strong>
            Promotes hateful content targeting any individual, race, ethnicity, religion, gender, or other protected
            group.
          </span>
        </li>
        <li class="flex gap-x-3">
          <svg
            class="mt-1 h-5 w-5 flex-none text-indigo-600"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
              fill-rule="evenodd"
            />
          </svg>

          <span>
            <strong class="font-semibold text-gray-900"></strong>
            Promotes or engages in illegal activities, including but not limited to, drug use, theft, or fraud.
          </span>
        </li>
        <li class="flex gap-x-3">
          <svg
            class="mt-1 h-5 w-5 flex-none text-indigo-600"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
              fill-rule="evenodd"
            />
          </svg>

          <span>
            <strong class="font-semibold text-gray-900"></strong>
            Infringes on intellectual property rights, including but not limited to, copyrights, trademarks, or patents.
          </span>
        </li>
        <li class="flex gap-x-3">
          <svg
            class="mt-1 h-5 w-5 flex-none text-indigo-600"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
              fill-rule="evenodd"
            />
          </svg>

          <span>
            <strong class="font-semibold text-gray-900"></strong>
            Discloses personal or confidential information of any individual.
          </span>
        </li>
        <li class="flex gap-x-3">
          <svg
            class="mt-1 h-5 w-5 flex-none text-indigo-600"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
              fill-rule="evenodd"
            />
          </svg>

          <span>
            <strong class="font-semibold text-gray-900"></strong>
            Encourages self-harm or suicide.
          </span>
        </li>
        <li class="flex gap-x-3">
          <svg
            class="mt-1 h-5 w-5 flex-none text-indigo-600"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
              fill-rule="evenodd"
            />
          </svg>

          <span>
            <strong class="font-semibold text-gray-900"></strong>
            Supports or promotes terrorist organizations or activities.
          </span>
        </li>
      </ul>

      <h3 class="mt-16 text-xl font-bold tracking-tight text-gray-900">2.2 Acceptable Use</h3>

      <p class="mt-6">
        Users must use the Site and its services responsibly and in compliance with these Terms and Conditions and all
        applicable laws and regulations.
      </p>

      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">3. Communication</h2>

      <p class="mt-6">
        The Company will use the email address provided by the User as the primary method of communication. Users are
        responsible for keeping their email address up-to-date.
      </p>

      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">
        4. Changes to Terms, Privacy Policy, and Pricing
      </h2>

      <p class="mt-6">
        The Company may modify these Terms and Conditions, the
        <router-link class="font-semibold text-indigo-600" :to="{ name: 'privacy_path' }">Privacy Policy</router-link>
        , and pricing at any time. Users will be notified of any changes via email or an announcement on the Site.
        Continued use of the Site after such changes constitutes acceptance of the new Terms and Conditions, Privacy
        Policy, and pricing.
      </p>

      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">
        5. Disclaimer of Warranties and Limitation of Liability
      </h2>

      <p class="mt-6">
        Users use the Site solely at their own risk. To the fullest extent permitted by law, the Company disclaims any
        and all warranties, express or implied, including but not limited to warranties of merchantability, fitness for
        a particular purpose, and non-infringement.
      </p>

      <p class="mt-6">
        In no event shall the Company be liable for any indirect, incidental, special, consequential, or punitive
        damages arising out of or in connection with the use of the Site or Products. The Company&rsquo;s liability
        shall be limited to the maximum extent permitted by law.
      </p>

      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">6. Indemnity and Hold Harmless</h2>

      <p class="mt-6">
        Users agree to indemnify, defend, and hold harmless the Company and its affiliates, licensors, and their
        respective officers, directors, employees, and agents from and against any and all claims, liabilities, damages,
        losses, or expenses, including reasonable attorneys&#39; fees and costs, arising out of or in any way connected
        with their access to or use of the Site and Products, violation of these Terms, or infringement of any
        intellectual property or other rights of any third party.
      </p>

      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">7. User Accounts</h2>

      <p class="mt-6">
        To access and use the Site, Users must create an account by providing accurate and complete information. Users
        are responsible for maintaining the confidentiality of their login credentials and are solely responsible for
        all activities that occur under their account.
      </p>

      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">8. User-Generated Content</h2>

      <p class="mt-6">
        Users acknowledge that they are solely responsible for the content they create using the Site, including any
        text, images, or designs. The Company has the right, but not the obligation, to review, remove, or modify any
        user-generated content that violates these Terms or is otherwise objectionable.
      </p>

      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">9. Payment and Billing</h2>

      <p class="mt-6">
        Users agree to pay all fees associated with the use of the Site and the fulfillment of Products according to the
        Company&rsquo;s payment terms. The Company may use a third-party payment processor to facilitate payments.
      </p>

      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">10. Dispute Resolution</h2>

      <p class="mt-6">
        Any disputes arising out of or in connection with these Terms shall be resolved according to the laws and
        jurisdiction of Prague, Czech Republic. Users agree to resolve any disputes through binding arbitration in
        accordance with the arbitration rules and process of the Czech Arbitration Court or, if applicable, in the
        courts located in Prague, Czech Republic.
      </p>

      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">11. Termination</h2>

      <p class="mt-6">
        The Company reserves the right to terminate a User&rsquo;s access to the Site at any time, with or without cause
        or notice, for any reason, including but not limited to a breach of these Terms. Users may also terminate their
        use of the Site by ceasing all use and deleting their account.
      </p>

      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">12. Contact Information</h2>

      <p class="mt-6">
        If you have any questions or concerns regarding these Terms and Conditions,
        <a
          class="font-semibold text-indigo-600"
          href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#115;&#117;&#112;&#112;&#111;&#114;&#116;&#64;&#112;&#114;&#105;&#110;&#116;&#112;&#108;&#117;&#115;&#46;&#97;&#112;&#112;"
        >
          please contact us by email
        </a>
        .
      </p>
    </div>
  </div>
</template>
