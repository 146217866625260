<template>
  <div class="w-full py-8 sm:py-16 bg-gray-100">
    <div class="mx-auto mb-20 max-w-7xl p-6">
      <div class="xl:mx-auto xl:max-w-7xl">
        <h1 class="text-4xl font-semibold mt-6">
          {{ $t('products.title') }}
        </h1>

        <h2 class="text-gray-500 mt-6 max-w-2xl">
          {{ $t('products.subtitle') }}
        </h2>

        <div v-if="loading" class="my-32 flex w-full items-center justify-center">
          <sl-spinner style="font-size: 5rem; --indicator-color: #4f46e5; --track-color: #eee"></sl-spinner>
        </div>

        <div v-else class="mt-16">
          <div class="gap-y-8 px-6 lg:px-8 xl:relative grid grid-cols-1 xl:grid-cols-4 xl:gap-x-6 xl:px-0">
            <router-link
              v-for="category in styleCategories"
              :key="category.slug"
              class="relative flex flex-col overflow-hidden rounded-lg hover:opacity-80 transition-all duration-300 xl:w-auto bg-white shadow-lg"
              :to="
                $localizedPath({
                  name: 'product_category_path',
                  params: {
                    category: category.slug
                  }
                })
              "
            >
              <div class="rounded-t-lg transition duration-1000 ease-in-out">
                <img class="rounded-t-lg object-cover" :src="category.image" />
              </div>
              <div class="flex flex-col justify-between p-4">
                <div class="flex items-center justify-between">
                  <h3 class="font-semibold" style="font-size: 0.9rem !important">
                    {{ $t(`style_categories.${category.slug}.plural`) }}
                  </h3>
                  <div class="flex gap-1">
                    <sl-tooltip v-if="offering[category.slug].organic_products" :content="$t('products.eco_tooltip')">
                      <div class="rounded bg-green-200 p-1">
                        <svg
                          class="h-4 w-4 text-green-700"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="1.5"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.75 3.03v.568c0 .334.148.65.405.864l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 01-1.161.886l-.143.048a1.107 1.107 0 00-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 01-1.652.928l-.679-.906a1.125 1.125 0 00-1.906.172L4.5 15.75l-.612.153M12.75 3.031a9 9 0 00-8.862 12.872M12.75 3.031a9 9 0 016.69 14.036m0 0l-.177-.529A2.25 2.25 0 0017.128 15H16.5l-.324-.324a1.453 1.453 0 00-2.328.377l-.036.073a1.586 1.586 0 01-.982.816l-.99.282c-.55.157-.894.702-.8 1.267l.073.438c.08.474.49.821.97.821.846 0 1.598.542 1.865 1.345l.215.643m5.276-3.67a9.012 9.012 0 01-5.276 3.67m0 0a9 9 0 01-10.275-4.835M15.75 9c0 .896-.393 1.7-1.016 2.25"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </sl-tooltip>
                    <sl-tooltip
                      v-if="offering[category.slug].premium_products"
                      :content="$t('products.premium_tooltip')"
                    >
                      <div class="rounded bg-yellow-200 p-1">
                        <svg
                          class="h-4 w-4 text-yellow-700"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="1.5"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </sl-tooltip>
                    <sl-tooltip :content="$t('products.sku_tooltip')">
                      <div class="rounded bg-gray-200 px-2 text-xs font-medium leading-6 text-gray-500">
                        {{ offering[category.slug].variants }} SKUs
                      </div>
                    </sl-tooltip>
                  </div>
                </div>
                <div class="mt-2 flex items-center justify-between text-xs">
                  <div>
                    <span class="text-gray-600">{{ $t('products.price_from') }}</span>
                    <span class="ml-1 font-medium">€{{ offering[category.slug].price_from }}</span>
                  </div>

                  <div class="flex items-center">
                    <span class="text-gray-600">{{ $t('products.shipping_from') }}</span>
                    <span class="ml-1 font-medium">€{{ shippingFrom(category.slug) }}</span>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'

import StyleService from '@/services/style_service'
import { StyleCategory } from '@/types'
import { useRoute } from 'vue-router'

const route = useRoute()
const styleCategories = ref(null as StyleCategory[] | null)
const offering = ref(null)
const loading = ref(true)

onMounted(async () => {
  const response = await StyleService.publicCategories()
  styleCategories.value = response.categories
  offering.value = response.offering
  loading.value = false
})

function shippingFrom(category: string) {
  return JSON.parse(offering.value[category].shipping_from)['eur']
}
</script>
