<template>
  <div class="w-full py-8 sm:py-16 bg-gray-100">
    <div class="mx-auto mb-20 max-w-7xl p-6">
      <Breadcrumbs
        :breadcrumbs="
          breadcrumbs
            .concat({
              name: $t(`style_categories.${category}.plural`),
              link: 'product_category_path',
              params: { category: category }
            })
            .concat({ name: $t(`product_quality_levels.${quality}`) })
        "
      />
      <div class="mt-6 flex gap-10">
        <div class="w-1/2">
          <img class="w-full" :src="categoryImagePath(category)" />
        </div>
        <div class="w-1/2">
          <div class="mb-3">
            <h1 class="text-3xl font-semibold">
              {{ $t(`product_category_quality.title.${category}.${quality}`) }}
            </h1>
          </div>

          <div class="text-gray-600">
            {{ $t(`product_category_quality.description.${category}.${quality}`) }}
          </div>

          <template v-if="brands[category]">
            <div class="mt-8 text-lg font-semibold">
              {{ $t(`product_category_quality.brands`) }}
            </div>
            <div class="flex items-center gap-2 mt-2">
              <div
                v-for="brand in brands[category][quality]"
                class="rounded-lg bg-green-200 text-green-700 px-3 py-1 text-sm font-medium"
              >
                {{ brand }}
              </div>
            </div>
          </template>

          <router-link
            :to="
              $localizedPath({
                name: 'product_design_path',
                params: {
                  category: category,
                  quality: quality
                }
              })
            "
          >
            <sl-button class="w-full mt-12" size="large" variant="primary">
              {{ $t('product_category_quality.cta_button') }}
            </sl-button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import Breadcrumbs from '@/components/shared/Breadcrumbs.vue'
import { Breadcrumb } from '@/types'
const i18n = useI18n()

const breadcrumbs = [{ name: i18n.t('breadcrumbs.all_products'), link: 'products_path' }] as Breadcrumb[]

const brands = {
  tshirts: {
    standard: ['Gildan', 'Fruit of the Loom'],
    premium: ['Gildan', 'B&C'],
    organic: ['Stanley/Stella', 'B&C']
  },
  hoodies: {
    standard: ['Awdis'],
    premium: ['B&C'],
    organic: ['Stanley/Stella']
  },
  sweatshirts: {
    standard: ['Awdis'],
    premium: ['B&C', 'Stanley/Stella'],
    organic: ['Stanley/Stella']
  },
  tote_bags: {
    standard: ['Jassz', 'Westford Mill'],
    premium: ['Liberty Bags'],
    organic: ['Stanley/Stella', 'Westford Mill', 'EarthPositive']
  }
}

const route = useRoute()
const category = computed(() => {
  return route.params.category as string
})

const quality = computed(() => {
  return route.params.quality as string
})

function categoryImagePath(category: string) {
  switch (category) {
    case 'thsirts':
      return 'https://img.printplus.app/categories/tshirts.png'
    case 'women_thsirts':
      return 'https://img.printplus.app/categories/tshirts.png'
    case 'hoodies':
      return 'https://img.printplus.app/categories/hoodies.png'
    case 'sweatshirts':
      return 'https://img.printplus.app/categories/sweatshirts.png'
    case 'polos':
      return 'https://img.printplus.app/categories/polos.png'
    case 'women_tank_tops':
      return 'https://img.printplus.app/categories/tank_tops.png'
    case 'tank_tops':
      return 'https://img.printplus.app/categories/tank_tops.png'
    case 'tote_bags':
      return 'https://img.printplus.app/categories/totes.png'
    default:
      return 'https://img.printplus.app/categories/tshirts.png'
  }
}
</script>
