<template>
  <div class="relative isolate -mx-8 overflow-hidden bg-white py-16 sm:-mx-64 sm:py-32">
    <div
      aria-hidden="true"
      class="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:left-1/2 md:top-20 lg:ml-20 xl:top-3 xl:ml-56"
    >
      <div
        class="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
        style="
          clip-path: polygon(
            63.1% 29.6%,
            100% 17.2%,
            76.7% 3.1%,
            48.4% 0.1%,
            44.6% 4.8%,
            54.5% 25.4%,
            59.8% 49.1%,
            55.3% 57.9%,
            44.5% 57.3%,
            27.8% 48%,
            35.1% 81.6%,
            0% 97.8%,
            39.3% 100%,
            35.3% 81.5%,
            97.2% 52.8%,
            63.1% 29.6%
          );
        "
      ></div>
    </div>
    <div class="mx-auto max-w-7xl px-8">
      <div class="mx-auto max-w-2xl sm:text-center">
        <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          {{ $t('homepage.features.title') }}
        </p>
        <p class="mt-6 text-lg leading-8 text-gray-600">
          {{ $t('homepage.features.subtitle') }}
        </p>
      </div>

      <div class="relative overflow-hidden pt-16">
        <div class="mx-auto max-w-7xl px-2 lg:px-8">
          <img
            alt="App screenshot"
            class="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
            height="1442"
            src="https://img.printplus.app/images/designer.jpg"
            width="2432"
          />
          <div aria-hidden="true" class="relative">
            <div class="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]"></div>
          </div>
        </div>
      </div>

      <div class="relative w-full">
        <svg
          aria-hidden="true"
          class="absolute -top-[80rem] left-[35rem] -z-10 h-[64rem] w-[105.5rem] -translate-x-1/2 stroke-gray-900/10 [mask-image:radial-gradient(48rem_48rem_at_111.5rem_0%,white,transparent)]"
        >
          <defs>
            <pattern id="e87443c8-56e4-4c20-9111-55b82fa704e3" height="200" patternUnits="userSpaceOnUse" width="200">
              <path d="M0.5 0V200M200 0.5L0 0.499983" />
            </pattern>
          </defs>
          <rect fill="url(#e87443c8-56e4-4c20-9111-55b82fa704e3)" height="100%" stroke-width="0" width="100%" />
        </svg>

        <svg
          aria-hidden="true"
          class="absolute -left-[20rem] -top-[23rem] -z-10 h-[64rem] w-[145.5rem] -translate-x-1/2 stroke-gray-900/10 [mask-image:radial-gradient(120rem_150rem_at_left,white,transparent)]"
        >
          <defs>
            <pattern id="e87443c8-56e4-4c20-9111-55b82fa704e3" height="200" patternUnits="userSpaceOnUse" width="200">
              <path d="M0.5 0V200M200 0.5L0 0.499983" />
            </pattern>
          </defs>
          <rect fill="url(#e87443c8-56e4-4c20-9111-55b82fa704e3)" height="100%" stroke-width="0" width="100%" />
        </svg>
      </div>

      <div class="mx-auto mt-16 max-w-7xl px-2 sm:mt-20 md:mt-24 lg:px-8">
        <dl
          class="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16"
        >
          <div class="relative pl-9">
            <dt class="inline font-semibold text-gray-900">
              <svg
                aria-hidden="true"
                class="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.055 7.06c-1.25-.714-2.805.189-2.805 1.628v8.123c0 1.44 1.555 2.342 2.805 1.628L12 14.471v2.34c0 1.44 1.555 2.342 2.805 1.628l7.108-4.061c1.26-.72 1.26-2.536 0-3.256L14.805 7.06C13.555 6.346 12 7.25 12 8.688v2.34L5.055 7.06z"
                />
              </svg>

              {{ $t('homepage.features.feature_1.name') }}
            </dt>
            <dd class="inline">{{ $t('homepage.features.feature_1.description') }}</dd>
          </div>
          <div class="relative pl-9">
            <dt class="inline font-semibold text-gray-900">
              <svg
                aria-hidden="true"
                class="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clip-rule="evenodd"
                  d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                  fill-rule="evenodd"
                />
              </svg>
              {{ $t('homepage.features.feature_2.name') }}
            </dt>
            <dd class="inline">{{ $t('homepage.features.feature_2.description') }}</dd>
          </div>
          <div class="relative pl-9">
            <dt class="inline font-semibold text-gray-900">
              <svg
                aria-hidden="true"
                class="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.721 12.752a9.711 9.711 0 00-.945-5.003 12.754 12.754 0 01-4.339 2.708 18.991 18.991 0 01-.214 4.772 17.165 17.165 0 005.498-2.477zM14.634 15.55a17.324 17.324 0 00.332-4.647c-.952.227-1.945.347-2.966.347-1.021 0-2.014-.12-2.966-.347a17.515 17.515 0 00.332 4.647 17.385 17.385 0 005.268 0zM9.772 17.119a18.963 18.963 0 004.456 0A17.182 17.182 0 0112 21.724a17.18 17.18 0 01-2.228-4.605zM7.777 15.23a18.87 18.87 0 01-.214-4.774 12.753 12.753 0 01-4.34-2.708 9.711 9.711 0 00-.944 5.004 17.165 17.165 0 005.498 2.477zM21.356 14.752a9.765 9.765 0 01-7.478 6.817 18.64 18.64 0 001.988-4.718 18.627 18.627 0 005.49-2.098zM2.644 14.752c1.682.971 3.53 1.688 5.49 2.099a18.64 18.64 0 001.988 4.718 9.765 9.765 0 01-7.478-6.816zM13.878 2.43a9.755 9.755 0 016.116 3.986 11.267 11.267 0 01-3.746 2.504 18.63 18.63 0 00-2.37-6.49zM12 2.276a17.152 17.152 0 012.805 7.121c-.897.23-1.837.353-2.805.353-.968 0-1.908-.122-2.805-.353A17.151 17.151 0 0112 2.276zM10.122 2.43a18.629 18.629 0 00-2.37 6.49 11.266 11.266 0 01-3.746-2.504 9.754 9.754 0 016.116-3.985z"
                />
              </svg>
              {{ $t('homepage.features.feature_3.name') }}
            </dt>
            <dd class="inline">{{ $t('homepage.features.feature_3.description') }}</dd>
          </div>
          <div class="relative pl-9">
            <dt class="inline font-semibold text-gray-900">
              <svg
                aria-hidden="true"
                class="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clip-rule="evenodd"
                  d="M5.25 2.25a3 3 0 00-3 3v4.318a3 3 0 00.879 2.121l9.58 9.581c.92.92 2.39 1.186 3.548.428a18.849 18.849 0 005.441-5.44c.758-1.16.492-2.629-.428-3.548l-9.58-9.581a3 3 0 00-2.122-.879H5.25zM6.375 7.5a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z"
                  fill-rule="evenodd"
                />
              </svg>
              {{ $t('homepage.features.feature_4.name') }}
            </dt>
            <dd class="inline">
              {{ $t('homepage.features.feature_4.description') }}
            </dd>
          </div>
          <div class="relative pl-9">
            <dt class="inline font-semibold text-gray-900">
              <svg
                aria-hidden="true"
                class="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clip-rule="evenodd"
                  d="M9 4.5a.75.75 0 01.721.544l.813 2.846a3.75 3.75 0 002.576 2.576l2.846.813a.75.75 0 010 1.442l-2.846.813a3.75 3.75 0 00-2.576 2.576l-.813 2.846a.75.75 0 01-1.442 0l-.813-2.846a3.75 3.75 0 00-2.576-2.576l-2.846-.813a.75.75 0 010-1.442l2.846-.813A3.75 3.75 0 007.466 7.89l.813-2.846A.75.75 0 019 4.5zM18 1.5a.75.75 0 01.728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 010 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 01-1.456 0l-.258-1.036a2.625 2.625 0 00-1.91-1.91l-1.036-.258a.75.75 0 010-1.456l1.036-.258a2.625 2.625 0 001.91-1.91l.258-1.036A.75.75 0 0118 1.5zM16.5 15a.75.75 0 01.712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 010 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 01-1.422 0l-.395-1.183a1.5 1.5 0 00-.948-.948l-1.183-.395a.75.75 0 010-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0116.5 15z"
                  fill-rule="evenodd"
                />
              </svg>
              {{ $t('homepage.features.feature_5.name') }}
            </dt>
            <dd class="inline">{{ $t('homepage.features.feature_5.description') }}</dd>
          </div>
          <div class="relative pl-9">
            <dt class="inline font-semibold text-gray-900">
              <svg
                aria-hidden="true"
                class="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clip-rule="evenodd"
                  d="M8.25 6.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM15.75 9.75a3 3 0 116 0 3 3 0 01-6 0zM2.25 9.75a3 3 0 116 0 3 3 0 01-6 0zM6.31 15.117A6.745 6.745 0 0112 12a6.745 6.745 0 016.709 7.498.75.75 0 01-.372.568A12.696 12.696 0 0112 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 01-.372-.568 6.787 6.787 0 011.019-4.38z"
                  fill-rule="evenodd"
                />
                <path
                  d="M5.082 14.254a8.287 8.287 0 00-1.308 5.135 9.687 9.687 0 01-1.764-.44l-.115-.04a.563.563 0 01-.373-.487l-.01-.121a3.75 3.75 0 013.57-4.047zM20.226 19.389a8.287 8.287 0 00-1.308-5.135 3.75 3.75 0 013.57 4.047l-.01.121a.563.563 0 01-.373.486l-.115.04c-.567.2-1.156.349-1.764.441z"
                />
              </svg>
              {{ $t('homepage.features.feature_6.name') }}
            </dt>
            <dd class="inline">{{ $t('homepage.features.feature_6.description') }}</dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>
