<template>
  <div class="flex min-h-screen w-full items-center justify-center">
    <sl-spinner style="font-size: 5rem; --indicator-color: #4f46e5; --track-color: #eee"></sl-spinner>
  </div>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import OrderService from '@/services/order_service'

const route = useRoute()
const router = useRouter()

onMounted(async () => {
  await OrderService.cancelCheckout(route.params.orderId as string, route.params.orderKey as string)
  router.push({ name: 'shopping_cart_path', params: { status: 'cancelled' } })
})
</script>
