<template>
  <div class="overflow-hidden bg-white font-sans antialiased">
    <div v-if="loading"></div>
    <div v-else class="relative overflow-hidden">
      <main class="mx-auto max-w-6xl">
        <h1 class="my-6 text-center text-2xl">{{ productPreview.title }}</h1>
        <div class="flex justify-center gap-4">
          <sl-tooltip v-for="variant in productPreview.variants" :key="variant.name" :content="variant.name">
            <div
              class="flex h-8 w-8 shrink-0 cursor-pointer items-center justify-center rounded-lg border border-gray-200 hover:border-gray-400 [&_sl-icon]:hover:block"
              :class="{ 'border-2 border-indigo-400 ring-2': selectedVariant.name === variant.name }"
              :style="`background-color: ${variant.hex_color}`"
              @click="selectedVariant = variant"
            ></div>
          </sl-tooltip>
        </div>
        <div class="grid grid-cols-2 gap-10">
          <div v-for="preview in selectedVariant.previews" :key="preview.location" class="flex flex-col items-center">
            <h2 class="text-xl font-semibold uppercase">{{ preview.location }}</h2>
            <img class="mt-4" :src="preview.image" />
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import { ProductPreview } from '@/services/product_service'
import ProductService from '@/services/product_service'

const productPreview = ref({} as ProductPreview)
const selectedVariant = ref({} as any)
const route = useRoute()
const loading = ref(true)

onMounted(async () => {
  productPreview.value = await ProductService.preview(route.params.id as string)
  selectedVariant.value = productPreview.value.variants[0]
  loading.value = false
})
</script>
