<template>
  <div class="flex flex-wrap gap-4 p-4">
    <div v-for="(pvs, color) in productVariantsByColor" :key="color">
      <sl-tooltip :content="color">
        <svg
          class="h-[80px] w-[80px] cursor-pointer items-center justify-center border border-gray-200"
          :class="modelValue.variant.id === pvs[0].variant.id ? 'border-gray-500' : 'hover:border-gray-400'"
          :style="`background-color: ${pvs[0].variant.hex_color}`"
          @click="$emit('update:modelValue', pvs[0])"
        >
          <rect height="100%" :style="`fill: ${pvs[0].variant.hex_color}`" width="100%"></rect>
          <image
            v-if="pvs[0].variant.dark_heather"
            height="100%"
            href="https://img.printplus.app/images/heather_dark_500.png"
            width="100%"
          />

          <image
            v-if="pvs[0].variant.light_heather"
            height="100%"
            href="https://img.printplus.app/images/heather_light_500.png"
            width="100%"
          />

          <image height="100%" :href="designArea?.image" width="100%" />
        </svg>
      </sl-tooltip>
    </div>
  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash'
import { computed } from 'vue'

import { ProductVariant, StyleDesignArea } from '@/types'

const props = defineProps<{
  designArea: StyleDesignArea
  productVariants: ProductVariant[]
  modelValue: ProductVariant
}>()

const productVariantsByColor = computed(() => {
  return _.groupBy(props.productVariants, (pv: ProductVariant) => pv.variant.name)
})
</script>
