<template>
  <div>
    <div class="mx-auto mb-32 mt-20 flex w-full max-w-md flex-col items-center">
      <div><sl-icon class="mt-6 text-4xl text-gray-500" name="cart-x"></sl-icon></div>
      <div class="mt-4 text-center text-gray-600">{{ $t('shopping_cart.empty_state.title') }}</div>
      <div class="mt-1 text-center text-sm text-gray-400">{{ $t('shopping_cart.empty_state.subtitle') }}</div>
      <router-link :to="{ name: 'products_path' }">
        <sl-button class="mt-6" variant="primary">{{ $t('shopping_cart.empty_state.cta_button') }}</sl-button>
      </router-link>
    </div>
  </div>
</template>
