<template>
  <div v-if="shippingPrices" class="w-full py-8 sm:py-16">
    <DecorativeGrid />

    <DecorativeGradients />

    <div
      class="relative z-30 mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8"
    >
      <div>
        <div class="text-base font-semibold leading-7 text-indigo-600">{{ $t('pricing.caption') }}</div>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          {{ $t('pricing.title') }}
        </h2>
        <p class="mt-4 text-gray-500">
          {{ $t('pricing.subtitle') }}
        </p>

        <dl class="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
          <div v-for="component in components" :key="component.name" class="border-t border-gray-200 pt-4">
            <dt class="font-medium text-gray-900">{{ component.name }}</dt>
            <dd class="mt-2 text-sm text-gray-500">{{ component.description }}</dd>
          </div>
        </dl>
      </div>
      <div class="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
        <img alt="Stacks of t-shirts." class="rounded-lg bg-gray-100" src="@/assets/images/products.jpg" />
        <img alt="Color swatches." class="rounded-lg bg-gray-100" src="@/assets/images/colors.jpg" />
        <img alt="A view of a screen printing press." class="rounded-lg bg-gray-100" src="@/assets/images/print.jpg" />
        <img
          alt="Eco packaging used for shipping apparel."
          class="rounded-lg bg-gray-100"
          src="@/assets/images/packaging.jpg"
        />
      </div>
    </div>

    <div class="mx-auto max-w-7xl lg:px-8">
      <h1 class="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        {{ $t('pricing.product_and_printing.title') }}
      </h1>

      <div
        class="relative z-30 mx-auto mt-8 w-full max-w-4xl justify-center rounded-xl bg-gray-50 py-6 text-xs ring-1 ring-gray-100 sm:py-10 sm:text-base"
      >
        <div class="mb-4 flex w-full justify-center gap-2">
          <div v-for="category in publicStyleCategories" :key="category.id" class="hidden sm:flex">
            <div
              class="flex cursor-pointer items-center rounded-md border py-2 text-xs font-medium only:px-3"
              :class="
                category.slug === selectedCategoryPrint
                  ? 'bg-white shadow-sm border-gray-200 text-indigo-600'
                  : 'text-gray-500 border-gray-50 hover:text-gray-700'
              "
              @click="selectedCategoryPrint = category.slug"
            >
              {{ $t(`style_categories.${category.slug}.plural`) }}
            </div>
          </div>
          <div class="flex sm:hidden">
            <sl-select
              size="small"
              :value="selectedCategoryPrint"
              @sl-change="selectedCategoryPrint = $event.target.value"
            >
              <sl-option v-for="category in publicStyleCategories" :key="category.id" :value="category.slug">
                {{ $t(`style_categories.${category.slug}.plural`) }}
              </sl-option>
            </sl-select>
          </div>
        </div>

        <div class="relative">
          <table class="w-full table-fixed border-separate border-spacing-x-4 text-left lg:border-spacing-x-8">
            <thead>
              <tr>
                <td class="w-1/3"></td>
                <th class="px-1 pt-2 sm:pb-4 xl:px-8 xl:pt-8" scope="col">
                  <div class="text-xs font-semibold leading-7 text-gray-900 sm:text-sm">
                    {{ $t('pricing.product_and_printing.one_design') }}
                  </div>
                </th>
                <th class="px-2 pt-2 sm:pb-4 xl:px-8 xl:pt-8" scope="col">
                  <div class="text-xs font-semibold leading-7 text-gray-900 sm:text-sm">
                    {{ $t('pricing.product_and_printing.additional_design') }}
                  </div>
                </th>
              </tr>
              <div class="absolute inset-x-4 h-px bg-gray-900/5 sm:inset-x-8"></div>
            </thead>
            <tbody>
              <tr>
                <td class="py-4 text-xs font-normal leading-6 text-gray-900 sm:text-sm">
                  <div class="flex items-center gap-2">
                    <sl-icon class="text-gray-500" name="piggy-bank"></sl-icon>
                    {{ $t('product_quality_levels.standard') }}
                  </div>
                  <div class="absolute inset-x-4 mt-4 h-px bg-gray-900/5 sm:inset-x-8"></div>
                </td>
                <td class="px-1 py-4 xl:px-8">
                  <Price :amount="printPricing[selectedCategoryPrint].standard.one[userSession.currentCurrency.code]" />
                </td>
                <td class="px-2 py-4 xl:px-8">
                  +
                  <Price
                    :amount="printPricing[selectedCategoryPrint].standard.additional[userSession.currentCurrency.code]"
                  />
                </td>
              </tr>
              <tr>
                <td class="py-4 text-xs font-normal leading-6 text-gray-900 sm:text-sm">
                  <div class="flex items-center gap-2">
                    <sl-icon class="text-yellow-600" name="patch-check"></sl-icon>
                    {{ $t('product_quality_levels.premium') }}
                  </div>
                  <div class="absolute inset-x-4 mt-4 h-px bg-gray-900/5 sm:inset-x-8"></div>
                </td>
                <td class="px-1 py-4 xl:px-8">
                  <Price :amount="printPricing[selectedCategoryPrint].premium.one[userSession.currentCurrency.code]" />
                </td>
                <td class="px-2 py-4 xl:px-8">
                  +
                  <Price
                    :amount="printPricing[selectedCategoryPrint].premium.additional[userSession.currentCurrency.code]"
                  />
                </td>
              </tr>
              <tr>
                <td class="py-4 text-xs font-normal leading-6 text-gray-900 sm:text-sm">
                  <div class="flex items-center gap-2">
                    <sl-icon class="text-green-700" name="recycle"></sl-icon>
                    {{ $t('product_quality_levels.organic') }}
                  </div>
                  <div class="absolute inset-x-4 mt-4 h-px bg-gray-900/5 sm:inset-x-8"></div>
                </td>
                <td class="px-1 py-4 xl:px-8">
                  <Price :amount="printPricing[selectedCategoryPrint].eco.one[userSession.currentCurrency.code]" />
                </td>
                <td class="px-2 py-4 xl:px-8">
                  +
                  <Price
                    :amount="printPricing[selectedCategoryPrint].eco.additional[userSession.currentCurrency.code]"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <h1 class="mt-20 text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        {{ $t('pricing.shipping_and_handling.title') }}
      </h1>

      <div
        class="mx-auto mt-8 w-full max-w-4xl justify-center rounded-xl bg-gray-50 py-6 ring-1 ring-gray-100 sm:py-10"
      >
        <div class="mb-4 flex w-full justify-center gap-2">
          <div v-for="category in publicStyleCategories" :key="category.id" class="hidden sm:flex">
            <div
              class="cursor-pointer rounded-md border px-3 py-2 text-sm font-medium"
              :class="
                category.slug === selectedCategoryShipping
                  ? 'bg-white shadow-sm border-gray-200 text-indigo-600'
                  : 'text-gray-500 border-gray-50 hover:text-gray-700'
              "
              @click="selectedCategoryShipping = category.slug"
            >
              {{ $t(`style_categories.${category.slug}.plural`) }}
            </div>
          </div>
          <div class="flex sm:hidden">
            <sl-select
              size="small"
              :value="selectedCategoryShipping"
              @sl-change="selectedCategoryShipping = $event.target.value"
            >
              <sl-option v-for="category in publicStyleCategories" :key="category.id" :value="category.slug">
                {{ $t(`style_categories.${category.slug}.plural`) }}
              </sl-option>
            </sl-select>
          </div>
        </div>

        <div class="relative">
          <div v-for="(prices, basePrice, index) in shippingPricesGroupedByBasePrice" :key="basePrice">
            <div class="mt-6 px-3 text-base font-medium sm:mt-10 sm:px-8 sm:text-xl">
              {{ $t('pricing.shipping_and_handling.zone') }} #{{ index + 1 }}
            </div>
            <table class="w-full table-fixed border-separate border-spacing-x-2 text-left sm:border-spacing-x-8">
              <thead>
                <tr>
                  <td class="w-1/3"></td>
                  <th class="px-2 xl:px-6" scope="col">
                    <div class="py-2 text-xs font-semibold leading-5 text-gray-900 sm:py-4 sm:text-sm">
                      {{ $t('pricing.shipping_and_handling.single_product') }}
                    </div>
                  </th>
                  <th class="px-2 xl:px-6" scope="col">
                    <div class="py-4 text-xs font-semibold leading-5 text-gray-900 sm:text-sm">
                      {{ $t('pricing.shipping_and_handling.additional_product') }}
                    </div>
                  </th>
                  <th class="px-2 xl:px-6" scope="col">
                    <div class="py-4 text-xs font-semibold leading-5 text-gray-900 sm:text-sm">
                      {{ $t('pricing.shipping_and_handling.oversized') }}
                    </div>
                  </th>
                  <th class="hidden px-2 sm:flex xl:px-6" scope="col">
                    <div class="py-4 text-xs font-semibold leading-5 text-gray-900 sm:text-sm">
                      {{ $t('pricing.shipping_and_handling.delivery_days') }}
                    </div>
                  </th>
                </tr>
                <div class="absolute inset-x-2 h-px bg-gray-900/5 sm:inset-x-8"></div>
              </thead>
              <tbody>
                <tr
                  v-for="shippingPrice in pricesSortedByCountryName(prices)"
                  :key="shippingPrice"
                  class="text-xs sm:text-sm"
                >
                  <td class="py-3 text-xs font-normal leading-4 text-gray-900 sm:text-sm sm:leading-6">
                    <div class="flex items-center gap-2">
                      <img
                        class="h-4 w-6 rounded border border-gray-200 object-cover"
                        :src="`https://flagcdn.com/h40/${shippingPrice.country_code.toLowerCase()}.png`"
                      />
                      {{ $t(`countries.${shippingPrice.country_code}`) }}
                    </div>
                    <div class="absolute inset-x-2 mt-3 h-px bg-gray-900/5 sm:inset-x-8"></div>
                  </td>
                  <td class="px-2 py-3 xl:px-6">
                    <Price :amount="shippingPrice.parameters.base_price[userSession.currentCurrency.code]" />
                  </td>
                  <td class="px-2 py-3 xl:px-6">
                    +
                    <Price :amount="shippingPrice.parameters.additional[userSession.currentCurrency.code] || 0" />
                  </td>
                  <td class="px-2 py-3 xl:px-6">
                    +
                    <Price :amount="shippingPrice.parameters.oversized[userSession.currentCurrency.code] || 0" />
                  </td>
                  <td class="hidden px-2 py-3 sm:flex xl:px-6">
                    {{ shippingPrice.parameters.days_from }} - {{ shippingPrice.parameters.days_to }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useSeoMeta } from '@unhead/vue'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import DecorativeGradients from '@/components/shared/DecorativeGradients.vue'
import DecorativeGrid from '@/components/shared/DecorativeGrid.vue'
import Price from '@/components/shared/Price.vue'
import { Configuration } from '@/stores/admin/configuration'
import { UserSession } from '@/stores/user_session'

const i18n = useI18n()
const configuration = Configuration()
const selectedCategoryPrint = ref('tshirts')
const selectedCategoryShipping = ref('tshirts')
const userSession = UserSession()

const printPricing = {
  tshirts: {
    standard: {
      one: { eur: 11.99 },
      additional: { eur: 4.99 }
    },
    premium: {
      one: { eur: 12.99 },
      additional: { eur: 4.99 }
    },
    eco: {
      one: { eur: 13.99 },
      additional: { eur: 4.99 }
    }
  },
  women_tshirts: {
    standard: {
      one: { eur: 11.99 },
      additional: { eur: 4.99 }
    },
    premium: {
      one: { eur: 12.99 },
      additional: { eur: 4.99 }
    },
    eco: {
      one: { eur: 13.99 },
      additional: { eur: 4.99 }
    }
  },
  men_tanktops: {
    standard: {
      one: { eur: 11.99 },
      additional: { eur: 4.99 }
    },
    premium: {
      one: { eur: 13.99 },
      additional: { eur: 4.99 }
    },
    eco: {
      one: { eur: 14.99 },
      additional: { eur: 4.99 }
    }
  },
  women_tanktops: {
    standard: {
      one: { eur: 11.99 },
      additional: { eur: 4.99 }
    },
    premium: {
      one: { eur: 13.99 },
      additional: { eur: 4.99 }
    },
    eco: {
      one: { eur: 14.99 },
      additional: { eur: 4.99 }
    }
  },
  hoodies: {
    standard: {
      one: { eur: 21.99 },
      additional: { eur: 5.99 }
    },
    premium: {
      one: { eur: 26.99 },
      additional: { eur: 5.99 }
    },
    eco: {
      one: { eur: 35.99 },
      additional: { eur: 5.99 }
    }
  },
  sweatshirts: {
    standard: {
      one: { eur: 18.99 },
      additional: { eur: 5.99 }
    },
    premium: {
      one: { eur: 23.99 },
      additional: { eur: 5.99 }
    },
    eco: {
      one: { eur: 30.99 },
      additional: { eur: 5.99 }
    }
  },
  polos: {
    standard: {
      one: { eur: 13.99 },
      additional: { eur: 4.99 }
    },
    premium: {
      one: { eur: 16.99 },
      additional: { eur: 4.99 }
    },
    eco: {
      one: { eur: 22.99 },
      additional: { eur: 4.99 }
    }
  },
  tote_bags: {
    standard: {
      one: { eur: 9.99 },
      additional: { eur: 4.99 }
    },
    premium: {
      one: { eur: 11.99 },
      additional: { eur: 4.99 }
    },
    eco: {
      one: { eur: 13.99 },
      additional: { eur: 4.99 }
    }
  }
}

useSeoMeta({
  title: i18n.t('pricing.seo.title'),
  description: i18n.t('pricing.seo.description'),
  ogDescription: i18n.t('pricing.seo.description'),
  ogTitle: i18n.t('pricing.seo.title'),
  ogImage: 'https://img.printplus.app/misc/og_image.jpg',
  ogUrl: 'https://printplus.app/pricing',
  ogSiteName: 'PrintPlus',
  ogType: 'website',
  ogLocale: i18n.locale
})

const publicStyleCategories = computed(() => {
  return configuration.styleCategories.filter((category) => category.public)
})

const shippingPrices = computed(() => {
  return configuration.shippingPrices[selectedCategoryShipping.value]
})

const shippingPricesGroupedByBasePrice = computed(() => {
  const currency = userSession.currentCurrency.code
  return [...shippingPrices.value]
    .sort((a, b) => {
      return a.parameters.base_price[currency] - b.parameters.base_price[currency]
    })
    .reduce((acc, shippingPrice) => {
      const basePrice = shippingPrice.parameters.base_price[currency]
      if (!acc[basePrice]) {
        acc[basePrice] = []
      }
      acc[basePrice].push(shippingPrice)
      return acc
    }, {})
})

const pricesSortedByCountryName = (prices: any[]) => {
  return prices.sort((a, b) => {
    const countryA = countryNameFromCode(a.country_code)
    const countryB = countryNameFromCode(b.country_code)
    if (countryA < countryB) {
      return -1
    }
    if (countryA > countryB) {
      return 1
    }
    return 0
  })
}

function countryNameFromCode(countryIsoCode: string) {
  return configuration.countries.find((country) => country.iso === countryIsoCode)?.name || countryIsoCode
}

const components = [...Array(4).keys()].map((i) => {
  return {
    name: i18n.t(`pricing.info.name_${i + 1}`),
    description: i18n.t(`pricing.info.description_${i + 1}`)
  }
})
</script>
