import { createRouter, createWebHistory } from 'vue-router'
import { useCookies } from 'vue3-cookies'

import { loadLocaleMessages, setI18nLanguage, SUPPORTED_LANGUAGES } from '@/i18n'
import Faq from '@/views/application/faq.vue'
import Features from '@/views/application/features.vue'
import ApplicationIndex from '@/views/application/index.vue'
import Layout from '@/views/application/layout.vue'
import NotFound from '@/views/application/not_found.vue'
import OrderCancel from '@/views/application/order/cancel.vue'
import Order from '@/views/application/order/show.vue'
import Pricing from '@/views/application/pricing.vue'
import Privacy from '@/views/application/privacy.vue'
import ProductCategory from '@/views/application/product_category.vue'
import ProductCategoryQuality from '@/views/application/product_category_quality.vue'
import ProductDesign from '@/views/application/product_design.vue'
import ProductPreview from '@/views/application/product_preview.vue'
import Products from '@/views/application/products.vue'
import ShippingCountries from '@/views/application/shipping_countries.vue'
import ShoppingCart from '@/views/application/shopping_cart.vue'
import SignIn from '@/views/application/sign_in.vue'
import SignUp from '@/views/application/sign_up.vue'
import Terms from '@/views/application/terms.vue'
import Tutorial from '@/views/application/tutorial.vue'

const { cookies } = useCookies()

export function setupRouter(i18n) {
  // setup routes
  const routes = [
    { path: '/sign-in', component: SignIn, name: 'sign_in_path' },
    { path: '/sign-up', component: SignUp, name: 'sign_up_path' },

    {
      path: '/:locale?',
      component: Layout,
      children: [
        { path: '', component: ApplicationIndex, name: 'root_path', meta: { hideHeader: true } },
        { path: 'features', component: Features, name: 'features_path' },
        { path: 'privacy', component: Privacy, name: 'privacy_path' },
        { path: 'terms', component: Terms, name: 'terms_path' },
        { path: 'pricing', component: Pricing, name: 'pricing_path' },
        { path: 'tutorial', component: Tutorial, name: 'tutorial_path' },
        { path: 'faq', component: Faq, name: 'faq_path' },
        { path: 'shipping-countries', component: ShippingCountries, name: 'shipping_countries_path' },
        { path: 'products/:id/preview', component: ProductPreview, name: 'product_preview_path' },

        { path: ':printer/products', component: Products, name: 'products_path', meta: { fullWidth: true } },
        {
          path: ':printer/products/:category',
          component: ProductCategory,
          name: 'product_category_path',
          meta: { fullWidth: true }
        },
        {
          path: ':printer/products/:category/:quality',
          component: ProductCategoryQuality,
          name: 'product_category_quality_path',
          meta: { fullWidth: true }
        },
        {
          path: ':printer/products/:category/:quality/design/:productId?',
          component: ProductDesign,
          name: 'product_design_path',
          meta: { fullWidth: true }
        },

        {
          path: ':printer/cart',
          component: ShoppingCart,
          name: 'shopping_cart_path',
          meta: { fullWidth: true }
        },

        {
          path: 'order/:orderId/:orderKey/cancel',
          component: OrderCancel,
          name: 'order_cancel_path'
        },

        {
          path: 'order/:orderId/:orderKey',
          component: Order,
          name: 'order_path'
        }
      ]
    },

    { path: '/404', component: NotFound },
    { path: '/:pathMatch(.*)*', component: NotFound }
  ]

  // create router instance
  const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
      // always scroll to top
      return { top: 0 }
    }
  })

  // navigation guards
  router.beforeEach(async (to) => {
    // TODO: use usePreferredLanguages from vue-use
    let locale = cookies.get('locale') || navigator.languages[0] || to.params.locale || navigator.language || 'en'

    if (to.params.locale === 'en') {
      return to.fullPath.replace(/^(\/en)/, '')
    }

    if (to.params.locale !== locale) {
      if (locale !== 'en') {
        return '/' + locale + to.fullPath
      } else if (to.params.locale) {
        const regex = new RegExp(`^(\/${to.params.locale})`, 'g')
        return to.fullPath.replace(regex, '')
      }
    }

    // use en locale if given locale is not in SUPPORTSUPPORTED_LANGUAGES_LOCALES
    if (!SUPPORTED_LANGUAGES.map((lang) => lang.locale).includes(locale)) {
      locale = 'en'
    }

    // load locale messages
    if (!i18n.global.availableLocales.includes(locale)) {
      await loadLocaleMessages(i18n, locale)
    }

    // set i18n language
    setI18nLanguage(i18n, locale)
  })

  return router
}
