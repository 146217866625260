<template>
  <div class="bg-white py-12 sm:py-24">
    <div class="mx-auto max-w-7xl lg:px-8">
      <div class="mx-auto max-w-2xl sm:text-center">
        <h2 class="text-base font-semibold leading-7 text-indigo-600">{{ $t('features.shopify_app.heading') }}</h2>
        <p class="mt-2 flex gap-4 text-3xl font-bold tracking-tight text-gray-900 sm:justify-center sm:text-4xl">
          <img class="h-10" src="@/assets/images/shopify.png" />
          {{ $t('features.shopify_app.title') }}
        </p>
        <p class="mt-6 text-lg leading-8 text-gray-600">
          {{ $t('features.shopify_app.subtitle') }}
        </p>
      </div>
    </div>
    <div class="relative overflow-hidden pt-16">
      <div class="mx-auto max-w-7xl lg:px-8">
        <img
          alt="App screenshot"
          class="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
          height="1442"
          src="https://img.printplus.app/images/designer.jpg"
          width="2432"
        />
        <div aria-hidden="true" class="relative">
          <div class="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]"></div>
        </div>
      </div>
    </div>
    <div class="mx-auto mt-16 max-w-7xl sm:mt-20 md:mt-24 lg:px-8">
      <dl
        class="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16"
      >
        <div class="relative pl-9">
          <dt class="inline font-semibold text-gray-900">
            <svg
              aria-hidden="true"
              class="absolute left-1 top-1 h-5 w-5 text-indigo-600"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                clip-rule="evenodd"
                d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z"
                fill-rule="evenodd"
              />
            </svg>
            {{ $t('features.shopify_app.feature1.title') }}
          </dt>
          <dd class="ml-1 inline">
            {{ $t('features.shopify_app.feature1.subtitle') }}
          </dd>
        </div>
        <div class="relative pl-9">
          <dt class="inline font-semibold text-gray-900">
            <svg
              aria-hidden="true"
              class="absolute left-1 top-1 h-5 w-5 text-indigo-600"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                clip-rule="evenodd"
                d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
                fill-rule="evenodd"
              />
            </svg>
            {{ $t('features.shopify_app.feature2.title') }}
          </dt>
          <dd class="ml-1 inline">{{ $t('features.shopify_app.feature2.subtitle') }}</dd>
        </div>
        <div class="relative pl-9">
          <dt class="inline font-semibold text-gray-900">
            <svg
              aria-hidden="true"
              class="absolute left-1 top-1 h-5 w-5 text-indigo-600"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                clip-rule="evenodd"
                d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z"
                fill-rule="evenodd"
              />
            </svg>
            {{ $t('features.shopify_app.feature3.title') }}
          </dt>
          <dd class="ml-1 inline">
            {{ $t('features.shopify_app.feature3.subtitle') }}
          </dd>
        </div>
        <div class="relative pl-9">
          <dt class="inline font-semibold text-gray-900">
            <svg
              aria-hidden="true"
              class="absolute left-1 top-1 h-5 w-5 text-indigo-600"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                clip-rule="evenodd"
                d="M10 2.5c-1.31 0-2.526.386-3.546 1.051a.75.75 0 01-.82-1.256A8 8 0 0118 9a22.47 22.47 0 01-1.228 7.351.75.75 0 11-1.417-.49A20.97 20.97 0 0016.5 9 6.5 6.5 0 0010 2.5zM4.333 4.416a.75.75 0 01.218 1.038A6.466 6.466 0 003.5 9a7.966 7.966 0 01-1.293 4.362.75.75 0 01-1.257-.819A6.466 6.466 0 002 9c0-1.61.476-3.11 1.295-4.365a.75.75 0 011.038-.219zM10 6.12a3 3 0 00-3.001 3.041 11.455 11.455 0 01-2.697 7.24.75.75 0 01-1.148-.965A9.957 9.957 0 005.5 9c0-.028.002-.055.004-.082a4.5 4.5 0 018.996.084V9.15l-.005.297a.75.75 0 11-1.5-.034c.003-.11.004-.219.005-.328a3 3 0 00-3-2.965zm0 2.13a.75.75 0 01.75.75c0 3.51-1.187 6.745-3.181 9.323a.75.75 0 11-1.186-.918A13.687 13.687 0 009.25 9a.75.75 0 01.75-.75zm3.529 3.698a.75.75 0 01.584.885 18.883 18.883 0 01-2.257 5.84.75.75 0 11-1.29-.764 17.386 17.386 0 002.078-5.377.75.75 0 01.885-.584z"
                fill-rule="evenodd"
              />
            </svg>
            {{ $t('features.shopify_app.feature4.title') }}
          </dt>
          <dd class="ml-1 inline">{{ $t('features.shopify_app.feature4.subtitle') }}</dd>
        </div>
        <div class="relative pl-9">
          <dt class="inline font-semibold text-gray-900">
            <svg
              aria-hidden="true"
              class="absolute left-1 top-1 h-5 w-5 text-indigo-600"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                clip-rule="evenodd"
                d="M7.84 1.804A1 1 0 018.82 1h2.36a1 1 0 01.98.804l.331 1.652a6.993 6.993 0 011.929 1.115l1.598-.54a1 1 0 011.186.447l1.18 2.044a1 1 0 01-.205 1.251l-1.267 1.113a7.047 7.047 0 010 2.228l1.267 1.113a1 1 0 01.206 1.25l-1.18 2.045a1 1 0 01-1.187.447l-1.598-.54a6.993 6.993 0 01-1.929 1.115l-.33 1.652a1 1 0 01-.98.804H8.82a1 1 0 01-.98-.804l-.331-1.652a6.993 6.993 0 01-1.929-1.115l-1.598.54a1 1 0 01-1.186-.447l-1.18-2.044a1 1 0 01.205-1.251l1.267-1.114a7.05 7.05 0 010-2.227L1.821 7.773a1 1 0 01-.206-1.25l1.18-2.045a1 1 0 011.187-.447l1.598.54A6.993 6.993 0 017.51 3.456l.33-1.652zM10 13a3 3 0 100-6 3 3 0 000 6z"
                fill-rule="evenodd"
              />
            </svg>
            {{ $t('features.shopify_app.feature5.title') }}
          </dt>
          <dd class="ml-1 inline">
            {{ $t('features.shopify_app.feature5.subtitle') }}
          </dd>
        </div>
        <div class="relative pl-9">
          <dt class="inline font-semibold text-gray-900">
            <svg
              aria-hidden="true"
              class="absolute left-1 top-1 h-5 w-5 text-indigo-600"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                d="M4.632 3.533A2 2 0 016.577 2h6.846a2 2 0 011.945 1.533l1.976 8.234A3.489 3.489 0 0016 11.5H4c-.476 0-.93.095-1.344.267l1.976-8.234z"
              />
              <path
                clip-rule="evenodd"
                d="M4 13a2 2 0 100 4h12a2 2 0 100-4H4zm11.24 2a.75.75 0 01.75-.75H16a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75h-.01a.75.75 0 01-.75-.75V15zm-2.25-.75a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75H13a.75.75 0 00.75-.75V15a.75.75 0 00-.75-.75h-.01z"
                fill-rule="evenodd"
              />
            </svg>
            {{ $t('features.shopify_app.feature6.title') }}
          </dt>
          <dd class="ml-1 inline">{{ $t('features.shopify_app.feature6.subtitle') }}</dd>
        </div>
      </dl>
    </div>
  </div>
</template>
