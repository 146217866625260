<template>
  <div class="mx-auto w-full px-0 py-24 sm:py-32 lg:w-1/2 lg:px-8 lg:py-40">
    <h2 class="text-2xl font-bold leading-10 tracking-tight">{{ $t('faq.title') }}</h2>
    <sl-input
      class="mt-6 sm:mt-10"
      :label="$t('faq.search_label')"
      :placeholder="$t('faq.search_placeholder')"
      @input="filter($event.target.value)"
    />
    <dl class="mt-6 space-y-6 sm:mt-10">
      <div v-for="faq in faqs" :key="faq">
        <div v-if="!faq.hidden" class="pt-6" @click="faq.expanded = !faq.expanded">
          <dt>
            <!-- Expand/collapse question button -->
            <button
              aria-controls="faq-0"
              aria-expanded="false"
              class="flex w-full items-start justify-between text-left text-gray-900"
              type="button"
            >
              <span class="text-base font-semibold leading-7">
                {{ faq.question }}
              </span>
              <span class="ml-6 flex h-7 items-center">
                <!--
                  Icon when question is collapsed.

                  Item expanded: "hidden", Item collapsed: ""
                -->
                <svg
                  aria-hidden="true"
                  class="h-6 w-6"
                  :class="{ 'hidden': faq.expanded }"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  @click="faq.expanded = !faq.expanded"
                >
                  <path d="M12 6v12m6-6H6" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <!--
                  Icon when question is expanded.

                  Item expanded: "", Item collapsed: "hidden"
                -->
                <svg
                  aria-hidden="true"
                  class="h-6 w-6"
                  :class="{ 'hidden': !faq.expanded }"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  @click="faq.expanded = !faq.expanded"
                >
                  <path d="M18 12H6" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
            </button>
          </dt>
          <dd v-show="faq.expanded" id="faq-0" class="mt-2 pr-12">
            <p class="text-base leading-7 text-gray-600">
              {{ faq.answer }}
            </p>
          </dd>
        </div>
      </div>
    </dl>
  </div>
</template>

<script setup>
import { useSeoMeta } from '@unhead/vue'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const i18n = useI18n()
const numberOfQuestions = 65

useSeoMeta({
  title: i18n.t('faq.seo.title'),
  description: i18n.t('faq.seo.description'),
  ogDescription: i18n.t('faq.seo.description'),
  ogTitle: i18n.t('faq.seo.title'),
  ogImage: 'https://img.printplus.app/misc/og_image.jpg',
  ogUrl: 'https://printplus.app/faq',
  ogSiteName: 'PrintPlus',
  ogType: 'website',
  ogLocale: i18n.locale
})

const faqs = ref(
  [...Array(numberOfQuestions).keys()].map((i) => {
    return {
      question: i18n.t(`faq.question_${i + 1}`),
      answer: i18n.t(`faq.answer_${i + 1}`)
    }
  })
)

function filter(target) {
  faqs.value.forEach((faq) => {
    faq.hidden = !(
      faq.question.toLowerCase().includes(target.toLowerCase()) ||
      faq.answer.toLowerCase().includes(target.toLowerCase())
    )
  })
}
</script>
