<template>
  <div class="-mx-8 bg-gray-800 sm:-mx-64">
    <div class="mx-auto max-w-6xl px-8 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8">
      <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">
        {{ $t('features.shopify_app_cta.title') }}
        <br />
        {{ $t('features.shopify_app_cta.subtitle') }}
      </h2>
      <div class="mt-10 flex items-center gap-x-6 lg:mt-0 lg:shrink-0">
        <a
          class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          href="https://apps.shopify.com/printplus"
        >
          {{ $t('features.shopify_app_cta.button') }}
        </a>
      </div>
    </div>
  </div>
</template>
