<template>
  <div class="w-full py-8 sm:py-16 bg-gray-100">
    <div v-if="shoppingCart.lineItems().length > 0" class="">
      <div class="mx-auto max-w-2xl px-4 pb-24 pt-8 sm:px-6 lg:max-w-7xl lg:px-8">
        <h1 class="text-3xl font-bold tracking-tight text-gray-900">{{ $t('shopping_cart.heading') }}</h1>
        <div class="mt-12 lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-12 xl:gap-x-16">
          <section aria-labelledby="cart-heading" class="lg:col-span-7">
            <h2 id="cart-heading" class="sr-only">{{ $t('shopping_cart.items_in_your_cart') }}</h2>

            <ShoppingCartLineItems @addMoreItems="addMoreItems" />

            <div class="mt-10 text-gray-700 text-medium cursor-pointer underline">
              <router-link :to="$localizedPath({ name: 'products_path' })">+ Add another product</router-link>
            </div>
          </section>

          <!-- Order summary -->
          <div class="lg:col-span-5">
            <Summary @checkout="checkout()" />
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <ShoppingCartEmptyState />
    </div>

    <sl-dialog ref="dialog" class="addToCartModal" no-header style="--width: 80vw; --body-spacing: 0px">
      <AddLineItemsModal :line-item="addingLineItem" @close="dialog.hide()" />
    </sl-dialog>
  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import ShoppingCartService from '@/services/shopping_cart_service'
import { useShoppingCart } from '@/stores/shopping_cart'
import { ShoppingCartLineItem } from '@/types'
import AddLineItemsModal from '@/views/application/shopping_cart/_add_line_items_modal.vue'
import ShoppingCartEmptyState from '@/views/application/shopping_cart/_empty_state.vue'
import ShoppingCartLineItems from '@/views/application/shopping_cart/_line_items.vue'
import Summary from '@/views/application/shopping_cart/_summary.vue'

const i18n = useI18n()
const dialog = ref(null)
const addingLineItem = ref(null)
const shoppingCart = useShoppingCart()

function addMoreItems(lineItem: ShoppingCartLineItem) {
  addingLineItem.value = lineItem
  dialog.value.show()
}

async function checkout() {
  const response = await ShoppingCartService.checkout(shoppingCart.lineItems(), i18n.locale.value)
  window.location.href = response.checkout_url
}
</script>

<style scoped>
.addToCartModal::part(panel) {
  max-width: 1250px !important;
}
</style>
