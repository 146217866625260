<template>
  <div class="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
    <div class="mx-auto max-w-2xl sm:text-center">
      <!-- <h2 class="text-base font-semibold leading-7 text-indigo-600">Everything you need</h2> -->
      <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        {{ $t('homepage.steps.title') }}
      </p>
      <p class="mt-6 text-lg leading-8 text-gray-600">
        {{ $t('homepage.steps.subtitle') }}
      </p>
    </div>
    <div class="mx-auto grid max-w-2xl grid-cols-1 gap-8 overflow-hidden pt-20 lg:mx-0 lg:max-w-none lg:grid-cols-4">
      <div v-for="(step, index) in steps" :key="step.name">
        <div class="flex items-center text-sm font-semibold leading-6 text-indigo-600">
          <svg aria-hidden="true" class="mr-4 h-1 w-1 flex-none" viewBox="0 0 4 4">
            <circle cx="2" cy="2" fill="currentColor" r="2" />
          </svg>

          {{ $t('homepage.steps.step_caption', { step: index + 1 }) }}
          <div
            aria-hidden="true"
            class="absolute -ml-2 h-px w-screen -translate-x-full bg-gray-900/10 sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0"
          />
        </div>
        <p class="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">{{ step.name }}</p>
        <p class="mt-1 text-base leading-7 text-gray-600">{{ step.description }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const i18n = useI18n()
const steps = [...Array(4).keys()].map((i) => {
  return {
    name: i18n.t(`homepage.steps.step_${i + 1}.name`),
    description: i18n.t(`homepage.steps.step_${i + 1}.description`)
  }
})
</script>
