declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $localizedPath: any
  }
}

export function localizedPath(to_router: any, locale = this.$i18n.locale) {
  const params = { ...to_router.params, locale: locale === 'en' ? '' : locale }
  return { ...to_router, params: params }
}
