<template>
  <div class="flex min-h-full items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
    <div class="w-full max-w-sm space-y-8">
      <div>
        <img alt="PrintPlus" class="mx-auto h-12 w-auto" src="@/assets/images/logo.png" />
        <h2 class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Sign in to your account</h2>
        <p class="mt-2 text-center text-sm text-gray-600">
          Don't have an account?
          <router-link class="font-medium text-indigo-600 hover:text-indigo-500" :to="{ name: 'sign_up_path' }">
            Sign up
          </router-link>
        </p>
      </div>
      <form class="mt-8 space-y-6" @submit.prevent="signIn()">
        <input name="remember" type="hidden" value="true" />
        <div class="-space-y-px rounded-md shadow-sm">
          <sl-input
            id="email"
            v-model="email"
            label="Email address"
            name="email"
            placeholder="Your email address"
            required
            type="email"
          ></sl-input>

          <sl-input
            id="password"
            v-model="password"
            label="Password"
            name="password"
            password-toggle
            placeholder="Your password"
            required
            type="password"
          ></sl-input>
        </div>

        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <input
              id="remember-me"
              class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              name="remember-me"
              type="checkbox"
            />
            <label class="ml-2 block text-sm text-gray-900" for="remember-me">Remember me</label>
          </div>

          <div class="text-sm">
            <a class="font-medium text-indigo-600 hover:text-indigo-500" href="#">Forgot your password?</a>
          </div>
        </div>

        <div>
          <div
            class="group relative flex w-full cursor-pointer justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            @click="signIn()"
          >
            <span class="absolute inset-y-0 left-0 flex items-center pl-3">
              <sl-icon class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" name="key" />
            </span>
            Sign in
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import UserService from '@/services/user_service'
import { UserSession } from '@/stores/user_session'
import { alert } from '@/utils/alert'

const userSession = UserSession()
const email = ref('')
const password = ref('')

async function signIn() {
  try {
    const response = await UserService.signIn({
      email: email.value,
      password: password.value
    })

    userSession.setCurrentUser(response)

    // TODO: implement referrer logic so you will be redirected to the page you were trying to access
    window.location.href = '/admin'
  } catch (error) {
    alert('Wrong email or password. Please try again.', 'danger')
  }
}
</script>
